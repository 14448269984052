import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
} from '@heroicons/react/outline'
import { useState, useEffect } from 'react'
import pubsub from 'sweet-pubsub'

export type NotificationT = {
  type: 'empty' | 'neutral' | 'success' | 'danger' | 'attention'
  title: React.ReactNode
  message?: React.ReactNode
  icon?: React.ReactNode
  duration?: number // seconds until it fades out
  onClick?: () => void
}

let currId = 0
const getUniqId = () => ++currId

export const NotificationsContainer = () => {
  const [notifications, setNotifications] = useState<(NotificationT & { id: number })[]>([])

  useEffect(() => {
    const addNotification = ({
      type,
      title,
      message,
      icon,
      duration = 6,
      onClick,
    }: NotificationT) => {
      const id = getUniqId()

      setNotifications((state) => [...state, { id, type, title, message, icon, duration, onClick }])

      setTimeout(() => {
        setNotifications((state) => state.filter((notification) => notification.id !== id))
      }, duration * 1000)
    }
    pubsub.on('notification', addNotification)
    return () => {
      pubsub.off('notification', addNotification)
    }
  }, [])

  return (
    <div className="position-fixed px-3 py-4 pe-none" style={{ top: 0, right: 0 }}>
      {notifications.map((notification) => (
        <Notification
          key={notification.id}
          notification={notification}
          removeNotification={() => {
            setNotifications((state) => state.filter(({ id }) => id !== notification.id))
          }}
        />
      ))}
    </div>
  )
}

const Notification = ({
  notification,
  removeNotification,
}: {
  notification: NotificationT & { id: number }
  removeNotification: () => void
}) => {
  return (
    <div
      className="toast fade show mb-3"
      role="alert"
      aria-live="assertive"
      onClick={notification.onClick}
    >
      <div className="toast-header">
        {notification.icon && <div className="flex-shrink-0 me-2">{notification.icon}</div>}
        <h6 className="fs-sm mb-0 me-auto">{notification.title}</h6>
        <button
          type="button"
          className="btn-close ms-2 mb-1"
          aria-label="Close"
          onClick={removeNotification}
        />
      </div>
      <div className="toast-body text-body">{notification.message}</div>
    </div>
  )
}

const show = (notification: NotificationT) => pubsub.emit('notification', notification)

const neutral = (options: Omit<NotificationT, 'type'>) =>
  show({
    type: 'neutral',
    icon: <InformationCircleIcon style={{ height: 24, width: 24, color: 'var(--bs-gray-500)' }} />,
    ...options,
  })
const success = (options: Omit<NotificationT, 'type'>) =>
  show({
    type: 'success',
    icon: <CheckCircleIcon style={{ height: 24, width: 24, color: 'var(--bs-green)' }} />,
    ...options,
  })
const danger = (options: Omit<NotificationT, 'type'>) =>
  show({
    type: 'danger',
    icon: <ExclamationCircleIcon style={{ height: 24, width: 24, color: 'var(--bs-red)' }} />,
    ...options,
  })

export const notification = { show, neutral, success, danger }
