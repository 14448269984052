import { useEffect, useState } from 'react'
import { API_CREATE_CHALLENGE, API_GET_RANDOM_CHALLENGE_NAME } from '../../constants/api'
import { api } from '../../lib/api'
import { copyToClipboard } from '../../lib/clipboard'
import { Analytics } from '../../lib/analytics'
import { RefreshIcon } from '@heroicons/react/outline'

export const CreateChallengePage = () => {
  const [name, setName] = useState('')
  const [isCustomSolution, setIsCustomSolution] = useState(false)
  const [solution, setSolution] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isCopied, setIsCopied] = useState(false)
  const [challengeUrl, setChallengeUrl] = useState<string | null>(null)

  const fetchRandomChallengeName = async () => {
    setIsSubmitting(true)
    try {
      const response = await api.get(API_GET_RANDOM_CHALLENGE_NAME)
      setName(response?.data?.challenge_name || '')
    } catch {}
    setIsSubmitting(false)
  }

  useEffect(() => {
    fetchRandomChallengeName()
  }, [])

  const handleSubmit = async () => {
    Analytics.tag('Create Challenge')
    setIsSubmitting(true)
    try {
      const response = await api.get(API_CREATE_CHALLENGE, {
        params: { challenge_name: name, solution: solution || undefined },
      })
      const url = response?.data?.challenge_url.replace(
        '/challenge/challenge_id=',
        '?challenge_id=',
      )
      setChallengeUrl(url)
    } catch {}
    setIsSubmitting(false)
  }

  return (
    <div className="mx-auto py-5 px-4" style={{ maxWidth: 600 }}>
      <h1>Create Group Challenge</h1>
      <p className="text-body">
        Create a WordPlay challenge to play with your friends and family. Enter a simple name for
        the challenge, and we'll create a link you can send to the group.
      </p>

      <label htmlFor="name" className="form-label">
        Enter Challenge Name
      </label>
      <div className="d-flex">
        <input
          autoFocus
          autoComplete="off"
          className="form-control"
          type="text"
          name="name"
          id="name"
          onChange={(e) => setName(e.target.value)}
          onKeyDown={(e) => {
            if (name.trim() && e.code === 'Enter') {
              handleSubmit()
            }
          }}
          value={name}
        />
        <RefreshIcon
          style={{ width: 24, marginLeft: 6, cursor: 'pointer' }}
          onClick={fetchRandomChallengeName}
        />
      </div>

      <div className="form-check form-switch mt-4">
        <input
          type="checkbox"
          className="form-check-input"
          id="switch1"
          checked={isCustomSolution}
          onChange={(e) => setIsCustomSolution(e.target.checked)}
        />
        <label className="form-check-label" htmlFor="switch1">
          Enter Custom Challenge Word
        </label>
      </div>

      {isCustomSolution && (
        <>
          <label htmlFor="solution" className="form-label mt-4">
            Enter Challenge Word
          </label>
          <input
            className="form-control"
            autoFocus
            autoComplete="off"
            type="text"
            name="solution"
            id="solution"
            onChange={(e) => setSolution(e.target.value)}
            onKeyDown={(e) => {
              if (solution.trim() && e.code === 'Enter') {
                handleSubmit()
              }
            }}
            value={solution}
          />
        </>
      )}

      <button
        type="button"
        className="btn btn-primary mt-4"
        onClick={() => handleSubmit()}
        disabled={isSubmitting || !name.trim()}
      >
        {isSubmitting && <span className="spinner-border spinner-border-sm me-2" />}
        Create Challenge
      </button>

      {challengeUrl && (
        <div className="card mt-4">
          <div className="card-body">
            <dt>Share this link with your friends:</dt>
            <dd>{challengeUrl}</dd>
            <a href={challengeUrl}>
              <button className="btn btn-primary btn-sm me-2">Play</button>
            </a>
            <a href={challengeUrl.replace('/play', '/results')}>
              <button className="btn btn-primary btn-sm me-2">Watch Live</button>
            </a>
            <button
              className="btn btn-outline-primary btn-sm"
              onClick={() => {
                copyToClipboard(challengeUrl)
                setIsCopied(true)
                setTimeout(() => setIsCopied(false), 2000)
              }}
            >
              {isCopied ? 'Copied' : 'Copy Link To Clipboard'}
            </button>
          </div>
        </div>
      )}
    </div>
  )
}
