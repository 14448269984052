import { ReactNode } from 'react'
import { KeyValue } from '../../lib/keyboard'
import { CharStatus } from '../../lib/statuses'
import './key.scss'

type Props = {
  children?: ReactNode
  value: KeyValue
  status?: CharStatus
  onClick: (value: KeyValue) => void
}

export const Key = ({ children, status, value, onClick }: Props) => {
  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    onClick(value)
    event.currentTarget.blur()
  }

  const getKeyType = value === 'SPACE' ? 'space' : ['DELETE', 'ENTER'].includes(value) ? 'action' : 'letter'

  return (
    <button
      className={`wp-key wp-key-${status} wp-key-type-${getKeyType} d-flex align-items-center justify-content-center fs-sm fw-bold user-select-none`}
      onClick={handleClick}
    >
      {children || value}
    </button>
  )
}
