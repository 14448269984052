import { observer } from 'mobx-react-lite'
import { useState, useEffect } from 'react'
import { api } from '../../lib/api'
import {
  API_SET_USER_CONNECT_WALLET,
  API_SET_USER_DISCONNECT_WALLET,
  API_USER_SYNC_LOCAL_STORAGE,
} from '../../constants/api'
import { copyToClipboard } from '../../lib/clipboard'
import { userIdStorage } from '../../lib/localStorage'
import { SetPlayerNameForm } from '../SetPlayerNameForm'
import { Analytics } from '../../lib/analytics'
import { useAuth0 } from '@auth0/auth0-react'
import { LoginButtons } from '../LoginButtons'
import { setState, state } from '../../state'
import { FlipCell } from '../grid/Cell/FlipCell'
import { Keyboard } from '../keyboard'

declare const window: any

export const UserSettingsPage = observer(() => {
  const { user } = useAuth0()
  const user_token = userIdStorage.get().userId
  const [isCopiedEmail, setIsCopiedEmail] = useState(false)
  const [isCopiedToken, setIsCopiedToken] = useState(false)
  const [isSynced, setIsSynced] = useState(false)
  const [isWalletUnlocked, setIsWalletUnlocked] = useState(false)

  useEffect(() => {
    const intervalId = setInterval(() => {
      const walletUnlockStatus = async () => {
        const data = await window.ethereum._metamask.isUnlocked()
        setIsWalletUnlocked(data)
      }
      walletUnlockStatus().catch(console.error)
    }, 1000)
    return () => clearInterval(intervalId)
  }, [isWalletUnlocked, setIsWalletUnlocked])

  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [defaultAccount, setDefaultAccount] = useState<string | null>(null)

  const disconnectWalletHandler = async () => {
    await api.post(API_SET_USER_DISCONNECT_WALLET, {
      user_token,
      eth_address: defaultAccount,
    })

    setDefaultAccount(null)
  }

  const connectWalletHandler = () => {
    if (window && window.ethereum && window.ethereum.isMetaMask) {
      window.ethereum
        .request({ method: 'eth_requestAccounts' })
        .then((result: any) => {
          accountChangedHandler(result[0])
          Analytics.tag('MetaMask Wallet Connected - Success')
        })
        .catch((error: any) => {
          setErrorMessage(error.message)
          Analytics.tag('MetaMask Wallet Connected - Fail')
        })
    } else {
      console.log('Need to install MetaMask')
      setErrorMessage('Please install MetaMask browser extension to interact')
    }
  }

  // update account, will cause component re-render
  const accountChangedHandler = (newAccount: any) => {
    setDefaultAccount(newAccount)
    saveWalletAddress(newAccount)
  }

  const saveWalletAddress = async (address: string) => {
    const apiRequest = await api.post(API_SET_USER_CONNECT_WALLET, {
      user_token,
      eth_address: address,
    })
    Analytics.tag('MetaMask Wallet Connected - Saved')

    return apiRequest
  }

  // const syncLocalStorage = async () => {
  //   return await api.post(API_USER_SYNC_LOCAL_STORAGE, {
  //     user_token,
  //     local_storage: localStorage,
  //   })
  // }

  return (
    <div className="container-fluid" style={{ maxWidth: 800 }}>
      <h1 className='mt-5 mb-3'>Player Settings</h1>
      <div className="card border-0 shadow-lg">
        <div className="card-header">
          <ul className="nav nav-tabs card-header-tabs" role="tablist">
            <li className="nav-item"><a className="nav-link active" href="#html1" data-bs-toggle="tab" role="tab" aria-controls="html1" aria-selected="true">Game Options</a></li>
            <li className="nav-item"><a className="nav-link" href="#result1" data-bs-toggle="tab" role="tab" aria-controls="result1" aria-selected="false">User</a></li>
            {isWalletUnlocked && (
              <li className="nav-item"><a className="nav-link" href="#pug1" data-bs-toggle="tab" role="tab" aria-controls="pug1" aria-selected="false">Connections</a></li>
            )}
          </ul>
        </div>
        <div className="card-body">
          <div className="tab-content">
            <div className="tab-pane fade active show" id="html1" role="tabpanel">
              <div className="mb-5 row align-items-center justify-content-between">
                <div className="col-md-5">
                  <label className="col-form-label">Appearance Settings</label>
                  <div className="form-check form-switch mb-4">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="publicswitch"
                      checked={state.isDarkMode}
                      onChange={(e) =>  setState({ isDarkMode: e.target.checked })}
                    />
                    <label className="form-check-label" htmlFor="publicswitch">
                      Dark Mode
                    </label>
                  </div>
                  <div className="form-check form-switch mb-md-0 mb-3">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="publicswitch"
                      checked={state.isHighContrastMode}
                      onChange={(e) => setState({ isHighContrastMode: e.target.checked })}
                    />
                    <label className="form-check-label" htmlFor="publicswitch">
                      High Contrast Mode
                    </label>
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="d-flex justify-content-center">
                    <FlipCell value="P" status="correct" flipIndex={0} />
                    <FlipCell value="L" status="present" flipIndex={1} />
                    <FlipCell value="A" status="absent" flipIndex={2} />
                    <FlipCell value="Y" status={undefined} flipIndex={3} />
                  </div>
                </div>
              </div>

              <div className="mb-3 row align-items-center justify-content-between">
                <div className="col-md-3">
                  <label className="col-form-label">Keyboard Layout</label>
                </div>
                <div className="col-md-9">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="keyboard-layout"
                      checked={!state.keyboardLayout || state.keyboardLayout === 'qwerty'}
                      onChange={(e) => setState({ keyboardLayout: 'qwerty' })}
                    />
                    <label className="form-check-label" htmlFor="ex-radio-4">QWERTY</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="keyboard-layout"
                      checked={state.keyboardLayout === 'alphabetic'}
                      onChange={(e) => setState({ keyboardLayout: 'alphabetic' })}
                    />
                    <label className="form-check-label" htmlFor="ex-radio-5">Alphabetic</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="keyboard-layout"
                      checked={state.keyboardLayout === 'dvorak'}
                      onChange={(e) => setState({ keyboardLayout: 'dvorak' })}
                    />
                    <label className="form-check-label" htmlFor="ex-radio-5">Dvorak</label>
                  </div>
                </div>
              </div>

              <Keyboard
                onChar={() => {}}
                onDelete={() => {}}
                onEnter={() => {}}
                onSpace={() => {}}
                onLeft={() => {}}
                onRight={() => {}}
              />

              <div className="row align-items-center justify-content-between">
                <div className="col-md-12">
                  <label className="col-form-label">Additional Game Modes</label>
                  <div className="form-check form-switch mb-4">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="publicswitch"
                      checked={state.isSpeedMode}
                      onChange={(e) =>  setState({ isSpeedMode: e.target.checked })}
                    />
                    <label className="form-check-label" htmlFor="publicswitch">
                      Speed Mode
                    </label>
                  </div>
                </div>
              </div>

              
            </div>
            <div className="tab-pane fade" id="result1" role="tabpanel">
              <div className="mb-3 row align-items-center justify-content-between">
                <label className="col-md-2 col-form-label" htmlFor="text-input">User Token</label>
                {user_token && (
                  <div className="col-md-8 mb-md-0 mb-2">
                    {user_token}
                    <button
                      className="btn btn-primary btn-sm py-0 px-1 ms-2"
                      onClick={() => {
                        copyToClipboard(user_token)
                        setIsCopiedToken(true)
                        setTimeout(() => setIsCopiedToken(false), 2000)
                      }}
                    >
                      {isCopiedToken ? 'Copied' : 'Copy'}
                    </button>
                  </div>
                )}
                <div className="col-md-2">
                  <LoginButtons className="btn btn-outline-primary mr-0" />
                </div>
              </div>
              <div className="mb-3 row align-items-center">
                <label className="col-md-2 col-form-label" htmlFor="text-input">Player Name</label>
                <div className="col-md-10">
                  <SetPlayerNameForm />
                </div>
              </div>
            </div>
            
              <div className="tab-pane fade" id="pug1" role="tabpanel">
                <div className="row align-items-center justify-content-between">
                  {defaultAccount ? (
                    <>
                      <label className="col-md-3 col-form-label" htmlFor="text-input">Wallet Address</label>
                      <div className="col-md-4 mb-md-0 mb-2">
                        {defaultAccount}
                        <button
                          className="btn btn-primary btn-sm py-0 px-1 ms-2"
                          onClick={() => {
                            copyToClipboard(defaultAccount)
                            setIsCopiedToken(true)
                            setTimeout(() => setIsCopiedToken(false), 2000)
                          }}
                        >
                          {isCopiedToken ? 'Copied' : 'Copy'}
                        </button>
                      </div>
                      <div className="col-md-5">
                        <button className="btn btn-outline-primary" onClick={disconnectWalletHandler}>
                          Disconnect MetaMask Wallet
                        </button>
                      </div>
                    </>
                  ) : (
                    <div className="col-md-12">
                      <button className="btn btn-outline-primary" onClick={connectWalletHandler}>
                        Connect MetaMask Wallet
                      </button>
                    </div>
                  )}
                </div>
                {errorMessage && (
                  <div className="mb-3 row align-items-center">
                    <label className="col-md-2 col-form-label" htmlFor="text-input">Error</label>
                    <div className="col-md-10">
                      {errorMessage}
                    </div>
                  </div>
                )}
              </div>
            
          </div>
        </div>
      </div>
    </div>
  )
})

/* <div className="mt-10 px-4 py-4 bg-white dark:bg-slate-800 shadow rounded-lg sm:p-6">
          <dt className="text-sm font-medium text-slate-600 dark:text-slate-400">
            Sync local gameplay
          </dt>
          <dd className="mt-1.5 mb-2 text-md font-semibold text-slate-800 break-all">
            <button
              className="mt-2 inline-flex items-center rounded-md border border-transparent shadow-sm px-6 py-2 bg-blue-600 font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 text-md disabled:opacity-50"
              onClick={() => {
                setIsSynced(true)
                syncLocalStorage()
                setTimeout(() => setIsSynced(false), 2000)
              }}
            >
              {isSynced ? 'Synced' : 'Sync'}
            </button>
          </dd>
        </div> */
