import { KeyValue } from '../../../lib/keyboard'
import { observer } from 'mobx-react-lite'
import QwertyKeyboard from './qwerty'
import AlphabeticKeyboard from './alphabetic'
import DvorakKeyboard from './dvorak'
import ColemakKeyboard from './colemak'

const KeyboardLayout = observer(
  ({
    type,
    onClick,
    charStatuses
  }: {
    type?: string
    onClick: (value: KeyValue) => void
    charStatuses: any
  }) => {

    if (type === 'alphabetic') {
      return <AlphabeticKeyboard onClick={onClick} charStatuses={charStatuses} />
    }
    else if (type === 'dvorak') {
      return <DvorakKeyboard onClick={onClick} charStatuses={charStatuses} />
    }
    else if (type === 'colemak') {
      return <ColemakKeyboard onClick={onClick} charStatuses={charStatuses} />
    }
    return <QwertyKeyboard onClick={onClick} charStatuses={charStatuses} />
  }
)

export default KeyboardLayout
