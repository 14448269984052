export const IS_LOCAL_DEV = window.location.host.includes('localhost:')
export const API_BASEURL =
  process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === 'production'
    ? process.env.REACT_APP_BASE_ENDPOINT_PRODUCTION
    : process.env.REACT_APP_BASE_ENDPOINT_DEVELOPMENT

export const API_GET_SOLUTION_WORD = `/api/get_solution_word`
export const API_GET_TURN_MESSAGE = `/api/get_turn_message`
export const API_CREATE_CHALLENGE = `/api/create_challenge`
export const API_CHECK_GUESS = `/api/check_guess`
export const API_FIRST_WORD_ANALYSIS = `/api/get_firstword_analysis`
export const API_SECOND_WORD_SUGGESTIONS = `/api/secondword/suggestions`
export const API_WORD = `/api/word`
export const API_WORD_DETAILS = `/api/get_word_detail`
export const API_ARTICLE = `/api/get_article`
export const API_CHALLENGE_RESULTS = `/api/challenge/stats`
export const API_FAQ = `/api/faq`
export const API_GET_RANDOM_USERNAME = `/api/get_random_username`
export const API_GET_RANDOM_CHALLENGE_NAME = `/api/challenge/create_name`
export const API_GET_GUESS_RESULTS = `/api/game/get_match_data`
export const API_GET_CURRENT_GAME_STATE = `/api/user/game/current`
export const API_GET_USER_TOKEN = `/api/user/token`
export const API_SET_PLAYER_NAME = `/api/user/set_player_name`
export const API_GET_USER = `/api/user`
export const API_GET_LIST_LETTER_COUNT = `/api/list`
export const API_CREATE_USER = `/api/user/create`
export const API_SET_USER_CONNECT_WALLET = `/api/user/metamask/connect`
export const API_SET_USER_DISCONNECT_WALLET = `/api/user/metamask/disconnect`
export const API_GET_END_GAME_MESSAGE = `/api/game/get_game_message`
export const API_SET_LIGHT_MODE = `/api/user/xcommand`
export const API_USER_LOGIN = `/api/user/login`
export const API_USER_SYNC_LOCAL_STORAGE = `/api/user/local_storage`
export const API_GET_USER_STATS = `/api/user/stats`
export const API_RESET_USER_STATS = `/api/user/stats/reset`
export const API_GET_GAME_TYPE = `/api/game/type`
export const API_GET_CUSTOM_GAMES = `/user/game/custom/list`
export const API_GET_CUSTOM_GAME = `/user/game/custom/detail`
export const API_SAVE_CUSTOM_GAME = `/api/user/game/custom/save`
export const API_SANDBOX_GRID = `/api/sandbox/grid`
export const API_USER_CUSTOM_GAMES_LIST = `/api/user/game/custom/list`
export const API_GET_CUSTOM_GAME_TYPE = `/api/game/custom/type`
export const API_SYS_LOG_EVENT = `/api/sys/log_event`