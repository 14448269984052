import { useState, useEffect } from 'react'
import {
  unstable_HistoryRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
  Location,
  matchPath,
} from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Alert } from './components/alerts/Alert'
import { Grid } from './components/grid/Grid'
import { Keyboard } from './components/keyboard'
import {
  WORD_NOT_FOUND_MESSAGE,
  ONLY_LETTERS_ALLOWED_MESSAGE,
  FLUID_GAME_GUESS_TOO_SHORT_MESSAGE,
  isBaseGameplayRoute,
} from './constants/strings'
import { isWinningWord } from './lib/words'
import {
  saveDailyGameCompletedDate,
  wonTodayDailyGame,
  setStoredGuesses,
  howToPlayStorage,
  unlimitedWelcomeStorage,
} from './lib/localStorage'

import './App.css'
import { BaseModal } from './components/modals/BaseModal'
import { Navigation } from './components/navigation'
import { Message } from './components/grid/Message'
import { history } from './history'
import { SupportPage } from './components/pages/Support'
import { AboutPage } from './components/pages/About'
import { GuidePage } from './components/pages/Guide'
import classNames from 'classnames'
import { FirstWordPage } from './components/pages/FirstWord'
import { Footer } from './components/Footer'
import { NotificationsContainer } from './components/Notification'
import { WordDetailsPage } from './components/pages/WordDetails'
import { LoginPage } from './components/pages/Login'
import { ArticlePage } from './components/pages/article'
import { getFlipDurationTotalMs } from './components/grid/Cell/FlipCell'
import { CreateChallengePage } from './components/pages/CreateChallenge'
import { useIsScreenMaxWidth } from './lib/media-query'
import { ChallengeResultsPage } from './components/pages/ChallengeResults'
import { ChallengeBox } from './components/ChallengeBox'
import { EndGameBox } from './components/endgame/EndGameBox'
import { setState, state } from './state'
import { WinFireworks } from './components/WinFireworks'
import { api, fetchAndSaveUserToken, isWordInWordList, fetchFluidGameTypeDetails } from './lib/api'
import { notification } from './components/Notification'
import { queryStringToObject } from './lib/url'
import {
  API_GET_USER,
  API_GET_SOLUTION_WORD,
  API_GET_CURRENT_GAME_STATE,
  API_SET_LIGHT_MODE,
  API_GET_CUSTOM_GAME_TYPE,
} from './constants/api'
import { UserSettingsPage } from './components/pages/UserSettings'
import { DailyBoxBig, DailyBoxSmall } from './components/DailyBox'
import _, { times } from 'lodash'
import { SecondWordPage } from './components/pages/SecondWord'
import pubsub from 'sweet-pubsub'
import { Auth0Provider } from '@auth0/auth0-react'
import { StatsPage } from './components/pages/Stats'
import { MetaTags } from './components/MetaTags'
import { GameHeadingDescription } from './components/GameHeadingDescription'
import { CustomGameFormPage } from './components/pages/CreateCustomGame'
import { SandboxGrid } from './components/pages/SandboxGrid'
import { UserCustomGamesManagementPage } from './components/pages/UserCustomGamesManagementPage'
import { BottomAd } from './components/ads/bottom'
import { SideAd } from './components/ads/side'
import { UnlimitedWelcomePage } from './components/pages/UnlimitedWelcome'
import { FAQPage } from './components/pages/FAQ'

declare const window: any

window.dataLayer.push({
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID || '',
})

const App = () => {
  return (
    <Auth0Provider
      domain="login.wordplay.com"
      clientId="7Hlt6GxCrV8WchbLT2ejipPaTBwaPaiI"
      redirectUri={window.location.origin}
    >
      <Router history={history}>
        <UnlimitedContainer />
      </Router>
    </Auth0Provider>
  )
}

const UnlimitedContainer = observer(() => {
  const location = useLocation()

  if (location.pathname === '/unlimited' && unlimitedWelcomeStorage.get().seen) {
    return <Navigate replace to="/" />
  }
  return <AppContainer />
})

const AppContainer = observer(() => {
  const location = useLocation()
  const [isLoading, setIsLoading] = useState(true)
  const [lastEventPublishedAt, setLastEventPublishedAt] = useState(0)

  useEffect(() => {
    window.dataLayer.push({
      dataLayer: { page: location.pathname + location.search },
      dataLayerName: 'PageDataLayer',
    })
  }, [location])

  useEffect(() => {
    const eventPublisher = (data: any) => {
      if (data.dataLayer.timestamp !== lastEventPublishedAt) {
        window.dataLayer.push(data)
      } else {
        setLastEventPublishedAt(data.dataLayer.timestamp)
      }
    }
    pubsub.on('gtm_event', eventPublisher)
    return () => pubsub.off('gtm_event', eventPublisher)
  }, [lastEventPublishedAt, setLastEventPublishedAt])

  useEffect(() => {
    if (state.isDarkMode) {
      document.body.classList.add('wp-dark-mode')
    } else {
      document.body.classList.remove('wp-dark-mode')
    }
  }, [state.isDarkMode])

  useEffect(() => {
    if (state.isHighContrastMode) {
      document.body.classList.add('wp-high-contrast-mode')
    } else {
      document.body.classList.remove('wp-high-contrast-mode')
    }
  }, [state.isHighContrastMode])

  useEffect(() => {
    ;(async () => {
      let match = matchPath('/shr/:challengeId', location.pathname)
      if (match) {
        window.location.href = `/challenge/play?challenge_id=${match.params.challengeId}`
      }

      const query = queryStringToObject(location.search)
      const challengeId = query.challenge_id?.toString()
      const mode = parseMode(location)

      let [gameType, mechanism] = parseGameType(location)
      const customTypeId = parseCustomGameType(location)

      try {
        const userToken = await fetchAndSaveUserToken()
        setState({
          userToken,
        })

        if (customTypeId) {
          setState({ customTypeId })
        }

        const customGameTypeData = await api.get(API_GET_CUSTOM_GAME_TYPE, {
          params: {
            custom_type_id: customTypeId,
            user_token: state.userToken,
          },
        })

        let guessCount = 0
        let listName = 'core'
        if (!customTypeId) {
          const gameTypeDetails = await fetchFluidGameTypeDetails({
            challengeId,
            mode,
            gameType,
            mechanism,
          })
          guessCount = gameTypeDetails?.guess_count
          listName = gameTypeDetails?.list_name || 'core'
          const heading = gameTypeDetails?.title
          const description = gameTypeDetails?.description
          const footer = gameTypeDetails?.footer

          setState({
            challengeId,
            mode,
            gameType,
            guessCount,
            listName,
            mechanism,
            heading,
            description,
            footer,
          })
        } else {
          let letterCountFromCustomGameType = customGameTypeData?.data?.type?.letter_count
          if (
            letterCountFromCustomGameType === null ||
            letterCountFromCustomGameType === undefined
          ) {
            mechanism = 'fluid'
            gameType = 'core'
          } else {
            mechanism = 'core'
            gameType = 'core'
          }
          //gameType = letterCountFromCustomGameType + 'letter'
          guessCount = letterCountFromCustomGameType + 1
          listName = gameType

          const heading = customGameTypeData?.data?.type?.type_name
          const description = customGameTypeData?.data?.type?.description
          const footer = ''
          setState({
            guessCount,
            mode,
            gameType,
            description,
            listName,
            mechanism,
            heading,
            footer,
          })
        }

        let currentGameResponse: any = null
        let game_id = null
        try {
          currentGameResponse = await api.get(API_GET_CURRENT_GAME_STATE, {
            params: {
              challenge_id: challengeId,
              mode,
            },
          })
          game_id = currentGameResponse?.data?.game_id
        } catch {
          console.log('API_GET_CURRENT_GAME_STATE error')
        }

        const [userResponse, solutionResponse] = await Promise.all([
          api.get(API_GET_USER),
          api.get(API_GET_SOLUTION_WORD, {
            params: {
              challenge_id: challengeId,
              mode,
              game_id,
              custom_type_id: customTypeId,
            },
          }),
        ])

        if (!game_id) {
          game_id = solutionResponse?.data?.game_id
        }

        const solution = solutionResponse?.data?.word
        const guessesResponse = () => {
          if (!currentGameResponse) {
            return []
          }

          const guesses = currentGameResponse?.data?.status?.guesses?.map((guess: string) =>
            guess.toUpperCase(),
          )
          if (!guesses || solution !== currentGameResponse?.data?.solution) return []
          if (challengeId || mode === currentGameResponse?.data?.mode) return guesses || []
          return []
        }

        let guesses: any = guessesResponse()
        /* ||
          getStoredGuesses({
            challengeId,
            mode,
            solution: solutionResponse?.data?.word,
          }) ||
          []
          */

        const isWin = guesses.some((guess: string) =>
          isWinningWord(solutionResponse?.data?.word, guess),
        )
        const isLoss = !isWin && guesses.length === solutionResponse?.data?.guess_count

        setState({
          solution: solutionResponse?.data?.word,
          letterCount: solutionResponse?.data?.letter_count,
          guessCount: guessCount || solutionResponse?.data?.guess_count,
          currentGuess: times(solutionResponse?.data?.letter_count, () => null),
          gameId: game_id,
          dailyId: solutionResponse?.data?.puzzle_number,
          playerName: userResponse?.data?.player_name,
          guesses,
          isGameWon: isWin,
          isGameLost: isLoss,
        })
        setIsLoading(false)
      } catch (error: any) {
        notification.danger({ title: error.message })
      }

      if (mode === 'random' && location.pathname === '/') {
        history.replace('/new')
      }
      if (
        mode === 'daily' &&
        location.pathname === '/' &&
        (window.location.hostname === 'wordplay.com' || window.location.hostname === 'localhost')
      ) {
        history.replace('/daily')
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading)
    return (
      <div className="d-flex pt-5 justify-content-center">
        <div className="spinner-border" />
      </div>
    )

  return (
    <>
      <MetaTags />
      <AppWithBasicData />
    </>
  )
})

const parseCustomGameType = (location: Location) => {
  const customTypeIdMatch = matchPath('/custom/:customTypeId/:customTypeName', location.pathname)
  const customTypeId = customTypeIdMatch?.params?.customTypeId
    ? customTypeIdMatch?.params?.customTypeId
    : null
  return customTypeId
}

const parseGameType = (location: Location) => {
  if (window.location?.hostname === 'wordplay.com' || window.location?.hostname === 'localhost') {
    const query = queryStringToObject(location.search)
    if (query.game_type) {
      if (query.game_type !== 'daily') {
        return [query.game_type as string, 'core']
      } else {
        return ['core', 'core']
      }
    }
    const match = matchPath('/game/:gameType', location.pathname)
    const gameType = match?.params?.gameType ? match?.params?.gameType : 'core'
    const mechanism = gameType === 'core' ? 'core' : 'fluid'

    return [gameType, mechanism]
  } else {
    if (
      window.location.hostname === 'marketing.wordplay.com' ||
      window.location.hostname === 'marketingword.com'
    ) {
      return ['martech', 'fluid']
    } else {
      return ['core', 'core']
    }
  }
}

const parseMode = (location: Location) => {
  if (window.location?.hostname === 'wordplay.com' || window.location?.hostname === 'localhost') {
    if (location.pathname === '/' && !wonTodayDailyGame()) return 'daily'
    if (location.pathname === '/daily') return 'daily'
    return 'random'
  } else {
    return 'random'
  }
}

const AppWithBasicData = observer(() => {
  const {
    solution,
    challengeId,
    letterCount,
    guessCount,
    mode,
    gameId,
    dailyId,
    isGameLost,
    isGameWon,
    guesses,
    currentGuess,
    currentGuessString,
    cursorIndex,
    mechanism,
    footer,
    isSpeedMode
  } = state

  const location = useLocation()
  const [isInvalidWord, setIsInvalidWord] = useState(false)
  const [isHowToPlayModalOpen, setIsHowToPlayModalOpen] = useState(!howToPlayStorage.get().seen)
  const [isUnlimitedWelcomeModalOpen, setIsUnlimitedWelcomeModalOpen] = useState(
    !unlimitedWelcomeStorage.get().seen && location.pathname === '/unlimited',
  )
  const [isNotOnlyLetters, setIsNotOnlyLetters] = useState(false)
  const [isFluidWordLengthTooShort, setIsFluidWordLengthTooShort] = useState(false)

  const onChar = (value: string | null) => {
    setIsNotOnlyLetters(false)
    setIsFluidWordLengthTooShort(false)
    setIsInvalidWord(false)

    const newCurrentGuess = [...state.currentGuess]
    newCurrentGuess[cursorIndex] = value
    setState({
      currentGuess: newCurrentGuess,
      cursorIndex: Math.min(letterCount - 1, cursorIndex + 1),
    })

    if (state.currentGuessString.toLowerCase() === 'xdark') {
      setState({ isDarkMode: true })
      api.get(API_SET_LIGHT_MODE, { params: { command: 'xdark' } })
    } else if (state.currentGuessString.toLowerCase() === 'xlite') {
      setState({ isDarkMode: false })
      api.get(API_SET_LIGHT_MODE, { params: { command: 'xlite' } })
    }
  }

  const onLeft = () => {
    setIsInvalidWord(false)

    setState({
      cursorIndex: Math.max(0, cursorIndex - 1),
    })
  }

  const onRight = () => {
    setIsInvalidWord(false)

    setState({
      currentGuess: currentGuess.length - 1 < cursorIndex ? [...currentGuess, null] : currentGuess,
      cursorIndex: Math.min(letterCount - 1, cursorIndex + 1),
    })
  }

  const onDelete = () => {
    setIsNotOnlyLetters(false)
    setIsFluidWordLengthTooShort(false)
    setIsInvalidWord(false)

    const newCurrentGuess = [...currentGuess]
    const newCursorIndex = newCurrentGuess[cursorIndex] ? cursorIndex : Math.max(0, cursorIndex - 1)
    newCurrentGuess[newCursorIndex] = null
    setState({
      currentGuess: newCurrentGuess,
      cursorIndex: newCursorIndex
    })
  }

  const onEnter = async () => {
    if (guesses.length >= guessCount) return
    if (mechanism !== 'fluid') {
      if (currentGuess.includes(null)) {
        setIsNotOnlyLetters(true)
        return
      }
    } else {
      if (Object.values(currentGuess).filter((b) => b !== null).length < 4) {
        setIsFluidWordLengthTooShort(true)
        return
      }
    }

    if (!(await isWordInWordList(currentGuessString))) {
      setIsInvalidWord(true)
      return
    }

    const isWin = isWinningWord(solution, currentGuessString)
    const isLoss = !isWin && guesses.length === guessCount - 1
    const newGuesses = [...guesses, currentGuessString]

    setState({
      guesses: newGuesses,
      currentGuess: times(letterCount, () => null),
      cursorIndex: 0,
    })
    setStoredGuesses(newGuesses)

    if (mode === 'daily' && (isWin || isLoss)) {
      saveDailyGameCompletedDate()
    }
    if (isWin) {
      setTimeout(() => {
        setState({ isGameWon: true })
      }, getFlipDurationTotalMs())
    }
    if (isLoss) {
      setTimeout(() => {
        setState({ isGameLost: true })
      }, getFlipDurationTotalMs())
    }
  }

  const isMaxWidth1023 = useIsScreenMaxWidth(1023)

  return (
    <div>
      <Navigation />

      <Routes>
        <Route path="/user/settings" element={<UserSettingsPage />} />
        <Route path="/user/stats" element={<StatsPage />} />
        <Route path="/user/game/manage" element={<CustomGameFormPage />} />
        <Route path="/user/game/manage/:id" element={<CustomGameFormPage />} />
        <Route path="/user/custom" element={<UserCustomGamesManagementPage />} />
        <Route path="/stats" element={<StatsPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/support" element={<SupportPage />} />
        <Route path="/guide" element={<GuidePage />} />
        <Route path="/faq" element={<FAQPage />} />
        <Route path="/firstword" element={<FirstWordPage />} />
        <Route path="/secondword" element={<SecondWordPage />} />
        <Route path="/word/:word" element={<WordDetailsPage />} />
        <Route path="/article/:id" element={<ArticlePage />} />
        <Route path="/challenge/new" element={<CreateChallengePage />} />
        <Route path="/challenge/results" element={<ChallengeResultsPage />} />
        <Route path="/sandbox/grid" element={<SandboxGrid />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/challenge/play" element={<></>} />
        <Route path="/shr/:challengeId" element={<></>} />
        <Route path="/game/:gameType" element={<></>} />
        <Route path="/custom/:customTypeId/:customTypeName" element={<></>} />
        {/* <Route path="/kirsten" element={<Navigate to="/game/6letter"  />} /> */}
        <Route path="/new" element={<></>} />
        <Route path="/daily" element={<></>} />
        <Route path="/unlimited" element={<></>} />
        <Route path="/" element={<></>} />
      </Routes>

      <div
        className="mx-auto mt-5 px-4"
        style={{
          maxWidth: 1280,
          display: isBaseGameplayRoute(location.pathname) ? 'block' : 'none',
        }}
      >
        <Alert message={ONLY_LETTERS_ALLOWED_MESSAGE} isOpen={isNotOnlyLetters} />
        <Alert message={FLUID_GAME_GUESS_TOO_SHORT_MESSAGE} isOpen={isFluidWordLengthTooShort} />
        <Alert message={WORD_NOT_FOUND_MESSAGE(currentGuessString)} isOpen={isInvalidWord} />

        <div className={classNames('cell-grid pb-4', { 'no-guesses': guesses.length === 0 })}>
          <div className="pe-3">
            {!isMaxWidth1023 && (
              <>
                <GameHeadingDescription />
                {challengeId && <ChallengeBox />}
                {(isGameLost || isGameWon) && <EndGameBox />}
                {!isGameLost && !isGameWon && mode === 'daily' && <DailyBoxBig />}
              </>
            )}
          </div>

          <div className="position-relative">
            {isMaxWidth1023 && !isGameLost && !isGameWon && mode === 'daily' && <DailyBoxSmall />}
            <Grid isInvalidWord={isInvalidWord} />
          </div>
          <div>
            {guesses?.length > 0 || process.env.REACT_APP_GOOGLE_ADSENSE_SQUARE_AD === 'false' ? times(guessCount).map((index) => (
              <Message key={index} index={index} />
            )) : (
              <SideAd className='ms-3'/>
            )}
          </div>
        </div>

        {!isGameWon && !isGameLost && (
          <Keyboard
            onChar={onChar}
            onDelete={onDelete}
            onEnter={onEnter}
            onSpace={() => onChar(null)}
            onLeft={onLeft}
            onRight={onRight}
          />
        )}

        {isMaxWidth1023 && <GameHeadingDescription />}
        {isMaxWidth1023 && challengeId && <ChallengeBox />}
        {isMaxWidth1023 && (isGameLost || isGameWon) && <EndGameBox />}

        {(isGameWon || isGameLost) && (
          <div className="d-flex justify-content-center fw-semibold text-uppercase fs-md text-muted">
            Press ENTER to start a new game!
          </div>
        )}

        <div className="d-flex justify-content-center mt-4 mb-3 fw-semibold text-uppercase fs-md text-muted">
          {mode === 'daily' ? `Daily Word #${dailyId} | Game ID: ${gameId}` : `ID: ${gameId}`}
        </div>

        {!isSpeedMode && <WinFireworks />}
       
      </div>

      <Footer extraText={footer} />

      {process.env.REACT_APP_GOOGLE_ADSENSE_HORIZONTAL_AD === 'true' && <BottomAd />}

      <BaseModal
        maxWidth={700}
        isOpen={isHowToPlayModalOpen}
        handleClose={() => setIsHowToPlayModalOpen(false)}
      >
        <GuidePage />
        <div style={{ textAlign: 'center', paddingBottom: 50 }}>
          <button
            type="button"
            className="btn btn-primary"
            style={{ maxWidth: 200 }}
            onClick={() => setIsHowToPlayModalOpen(false)}
          >
            Okay
          </button>
        </div>
      </BaseModal>

      <BaseModal
        maxWidth={700}
        title="What's going on?"
        isOpen={isUnlimitedWelcomeModalOpen}
        handleClose={() => {
          setIsUnlimitedWelcomeModalOpen(false)
          window.location = '/'
        }}
      >
        <UnlimitedWelcomePage />
        <div style={{ textAlign: 'center', paddingBottom: 50 }}>
          <button
            type="button"
            className="btn btn-primary"
            style={{ maxWidth: 200 }}
            onClick={() => {
              setIsUnlimitedWelcomeModalOpen(false)
              window.location = '/'
            }}
          >
            Okay
          </button>
        </div>
      </BaseModal>

      <NotificationsContainer />
    </div>
  )
})

export default App
