import { useEffect } from 'react'
import { CharStatus } from '../../../lib/statuses'
import { state } from '../../../state'
import './styles.scss'

export const Cell = ({
  value,
  status,
  onClick,
}: {
  value?: string | null
  status?: CharStatus
  onClick?: () => void
}) => {
  return (
    <div className={status === 'invalid' ? 'cell-wiggle' : undefined} onClick={onClick}>
      <div className={`wp-cell wp-cell-${status}`}>
        {value}
      </div>
    </div>
  )
}
