import { find } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { setState, state } from '../../state'
import { Cell } from './Cell'

export const CurrentRow = observer(({ isInvalidWord }: { isInvalidWord: boolean }) => {

  return (
    <div className="d-flex justify-content-center mb-1">
      {state.currentGuess.map((letter, i) => (
        <Cell
          key={i}
          value={
            letter === null
              ? !!find(state.currentGuess, (v) => v !== null, i) || state.cursorIndex > i
                ? '-'
                : null
              : letter
          }
          status={getStatus(isInvalidWord, state.cursorIndex === i)}
          onClick={() => setState({ cursorIndex: i })}
        />
      ))}
    </div>
  )
})

const getStatus = (isInvalidWord: boolean, isCursorIndex: boolean) => {
  if (isInvalidWord) return 'invalid'
  if (isCursorIndex) return 'typing'
  return undefined
}
