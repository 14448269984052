import { useState, useEffect } from 'react'
import { api } from '../../lib/api'
import { state } from '../../state'
import { API_USER_CUSTOM_GAMES_LIST } from '../../constants/api'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import renderActionsCell from './renderActionsCell'
import { parseWordsJSON } from './CreateCustomGame'

export const UserCustomGamesManagementPage = () => {
  const [data, setData] = useState<any>(null)
  const [columns, setColumns] = useState<any>([])

  useEffect(() => {
    ;(async () => {
      try {
        const response = await api.get(API_USER_CUSTOM_GAMES_LIST, {
          params: { user_token: state.userToken },
        })
        const game_types = response?.data.game_types
        const cols = Object.keys(game_types[0])
        let rows = []
        for (var i in game_types) {
          let thisRow = game_types[i]
          rows.push(thisRow)
        }

        let columnFields = []
        for (var i in cols) {
          if (i === '0') {
            columnFields.push({ field: cols[i], cellRenderer: renderActionsCell })
          } else {
            columnFields.push({ field: cols[i] })
          }
        }

        setData(rows || [])
        setColumns(columnFields || [])
      } catch {}
    })()
  }, [])

  return (
    <div className="mx-auto px-4 pt-5" style={{ maxWidth: 850 }}>
      <h1>
        Custom Games
        <span style={{ float: 'right' }}>
          <button
            type="button"
            className="btn btn-sm btn-success"
            onClick={() => {
              window.location.href = '/user/game/manage'
            }}
          >
            Create Custom Game
          </button>
        </span>
      </h1>
      {data && (
        <table className='table'>
          <thead>
            <tr>
              <th>Name</th>
              <th>Letters</th>
              <th>Words</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data.map((row: any) => (
              <tr key={`${row.id}/${row.slug}`}>
                <td>{row.heading || row.slug}</td>
                <td>{row.letter_count}</td>
                <td>{parseWordsJSON(row.words_json).split('\n').length}</td>
                <td className='text-end'>
                  <a href={`/user/game/manage/${row.id}`}>Edit</a> &nbsp;
                  <a href={`/custom/${row.id}/${row.slug}`}>Play</a>
                </td>
              </tr>
            ))}
            {data.length == 0 && (
              <tr>
                <td colSpan={4} className='text-center'>No custom games yet! Click "Create Custom Game" to create a new one.</td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </div>
  )
}
