import { useCallback, useEffect, useState } from 'react'
import { API_SAVE_CUSTOM_GAME, API_GET_CUSTOM_GAME_TYPE } from '../../constants/api'
import { api } from '../../lib/api'
import { state } from '../../state'
import { Analytics } from '../../lib/analytics'
import { notification } from '../Notification'
import { copyToClipboard } from '../../lib/clipboard'
import { useParams } from 'react-router-dom'

type GameT = {
  type_name: string
  description: string
  letter_count_type: string
  letter_count: string
  public: boolean
  words: string
}

const initialFields = {
  type_name: '',
  description: '',
  letter_count_type: 'fixed',
  letter_count: '5',
  public: true,
  words: '',
}

export const CustomGameFormPage = () => {
  const { id: gameId } = useParams()
  const [game, setGame] = useState<GameT>(initialFields)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [gameUrl, setGameUrl] = useState<null | string>(null)
  const [isCopied, setIsCopied] = useState(false)
  const mode = gameId ? 'update' : 'create'

  const updateGame = (data: Partial<GameT>) => {
    setGame((state) => ({ ...state, ...data }))
  }

  const wordsListEntry = (value: string) => {
    updateGame({ words: value.split(' ').join('\n') })
  }

  const fetchAndSetGame = useCallback(async () => {
    try {
      const response = await api.get(API_GET_CUSTOM_GAME_TYPE, {
        params: { custom_type_id: gameId },
      })
      const res = response.data?.type

      setGame({
        type_name: res?.type_name || initialFields.type_name,
        description: res?.description || initialFields.description,
        letter_count_type: res?.letter_count_type || initialFields.letter_count_type,
        letter_count: res?.letter_count || initialFields.letter_count,
        public: typeof res?.is_public === 'boolean' ? res.is_public : initialFields.public,
        words: parseWordsJSON(res?.words_json),
      })
    } catch {}
  }, [gameId])

  useEffect(() => {
    if (gameId) {
      fetchAndSetGame()
    }
  }, [gameId, fetchAndSetGame])

  const handleSubmit = async () => {
    if (mode === 'create') {
      Analytics.tag('Create Custom Game')
    } else {
      Analytics.tag('Update Custom Game')
    }

    setIsSubmitting(true)
    try {
      const response = await api.post(API_SAVE_CUSTOM_GAME, {
        custom_type_id: gameId ? Number(gameId) : null,
        user_token: state.userToken,
        type_name: game.type_name,
        description: game.description,
        letter_count_type: game.letter_count_type,
        letter_count: game.letter_count_type !== 'fluid' ? game.letter_count : null,
        public: game.public,
        words: game.words.split('\n'),
      })
      setGameUrl(response?.data?.play_link)
      if (mode === 'create') {
        setGame(initialFields)
      } else {
        fetchAndSetGame()
      }
    } catch (error: any) {
      notification.danger({ title: 'Error', message: error.message })
    }
    setIsSubmitting(false)
  }

  const handlePaste = (e: any) => {
    e.preventDefault();
    let text = (e.originalEvent || e).clipboardData.getData('text/plain');
    let strippedText = text.replace(/<[^>]*>?/gm, '').replaceAll(/[`"']/gm, '')
    let words = strippedText.trim().split(/[\s,]+/)
    wordsListEntry(words.join(' '))
  }

  useEffect(() => {
    let editor = document.getElementById('words')
    editor?.addEventListener("paste", handlePaste);
    return () => {
      editor?.removeEventListener("paste", handlePaste);
    }
  }, [])

  return (
    <div className="mx-auto py-5 px-4" style={{ maxWidth: 600 }}>
      <h1>{mode === 'create' ? `Create` : `Update`} Custom Game</h1>

      <p className="text-body">
        {mode === 'create' ? `Create` : `Update`} a custom WordPlay game to play with your friends
        and family. Enter the required data, and we'll create a link you can send to the group.
      </p>

      <label htmlFor="type_name" className="form-label mt-4">
        Game Name
      </label>
      <input
        autoComplete="off"
        disabled={mode === 'update'}
        className="form-control"
        type="text"
        name="type_name"
        id="type_name"
        onChange={(e) => updateGame({ type_name: e.target.value })}
        value={game.type_name}
      />

      <label htmlFor="description" className="form-label mt-4">
        Description
      </label>
      <input
        autoComplete="off"
        className="form-control"
        type="text"
        name="description"
        id="description"
        onChange={(e) => updateGame({ description: e.target.value })}
        value={game.description}
      />

      <label htmlFor="letters" className="form-label mt-4">
        Letter Count Type
      </label>
      <select
        className="form-select"
        id="letter_count_type"
        onChange={(e) => updateGame({ letter_count_type: e.target.value })}
        value={game.letter_count_type}
      >
        <option value="fixed">Fixed</option>
        <option value="fluid">Fluid</option>
      </select>

      {game?.letter_count_type !== 'fluid' && (
        <>
          <label htmlFor="letter_count" className="form-label mt-4">
            Letter Count
          </label>
          <select
            className="form-select"
            id="letter_count"
            onChange={(e) => updateGame({ letter_count: e.target.value })}
            value={game.letter_count}
          >
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
          </select>
        </>
      )}
      <div className="form-check form-switch mt-4">
        <input
          type="checkbox"
          className="form-check-input"
          id="publicswitch"
          checked={game.public}
          onChange={(e) => updateGame({ public: e.target.checked })}
        />
        <label className="form-check-label" htmlFor="publicswitch">
          Is Game Public
        </label>
      </div>

      <label htmlFor="words" className="form-label mt-4">
        Words List (one word per line)
      </label>
      <textarea
        className="form-control"
        id="words"
        rows={5}
        value={game.words}
        onChange={(e) => wordsListEntry(e.target.value)}
      />

      <button
        type="button"
        className="btn btn-primary mt-4"
        onClick={() => handleSubmit()}
        disabled={isSubmitting}
      >
        {isSubmitting && <span className="spinner-border spinner-border-sm me-2" />}
        {mode === 'create' ? `Create Custom Game` : `Update Custom Game`}
      </button>

      {gameUrl && (
        <>
          <h5
            className="card-title rounded py-2 px-4 mt-5"
            style={{ background: 'rgba(var(--bs-success-rgb), 0.3)', color: 'var(--bs-green)' }}
          >
            Game {mode === 'create' ? `Created` : `Updated`} Successfully
          </h5>
          <div className="card mt-3">
            <div className="card-body">
              <dt className="fs-md">Share this link with your friends:</dt>
              <dd>{gameUrl}</dd>
              <a href={gameUrl}>
                <button className="btn btn-primary btn-sm me-2">Play</button>
              </a>
              <button
                className="btn btn-outline-primary btn-sm"
                onClick={() => {
                  copyToClipboard(gameUrl)
                  setIsCopied(true)
                  setTimeout(() => setIsCopied(false), 2000)
                }}
              >
                {isCopied ? 'Copied' : 'Copy Link To Clipboard'}
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export const parseWordsJSON = (wordsJson: string) => {
  if (!wordsJson) return ''
  try {
    return JSON.parse(replaceAll(replaceAll(wordsJson, "', '", '", "'), "'", '"')).join('\n')
  } catch {
    return ''
  }
}

const replaceAll = (str: string, find: string, replace: string) => {
  return str.replace(new RegExp(find, 'g'), replace)
}
