import { useEffect, useState } from 'react'
import { API_WORD_DETAILS } from '../constants/api'
import { api } from '../lib/api'
import { LinkSaveParams } from './LinkSaveParams'

type DetailsT = {
  definition?: string
}

export const WordDefinition = ({ word }: { word: string }) => {
  const [details, setDetails] = useState<null | DetailsT>(null)

  useEffect(() => {
    ;(async () => {
      try {
        const response: { data: DetailsT } = await api.get(API_WORD_DETAILS, {
          params: { word },
        })
        setDetails(response.data)
      } catch {}
    })()
  }, [word])

  return (
    <div className="card mb-4">
      <div className="card-body">
        {details?.definition && (
          <dl className="mb-1">
            <dt className="fs-md">Definition for {word.toUpperCase()}:</dt>
            <dd className="mb-0">{details.definition}</dd>
          </dl>
        )}
        <LinkSaveParams to={`/word/${word}`} className="fs-md">
          Learn More About {word.toUpperCase()}
        </LinkSaveParams>
      </div>
    </div>
  )
}
