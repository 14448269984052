import { times } from 'lodash'
import { observer } from 'mobx-react-lite'
import { state } from '../../state'
import { CompletedRow } from './CompletedRow'
import { CurrentRow } from './CurrentRow'
import { EmptyRow } from './EmptyRow'

export const Grid = observer(({ isInvalidWord }: { isInvalidWord: boolean }) => {
  const { guesses, guessCount } = state

  return (
    <>
      {guesses.map((guess, i) => (
        <CompletedRow key={i} guess={guess} />
      ))}
      {guesses.length < guessCount && <CurrentRow isInvalidWord={isInvalidWord} />}
      {times(guessCount - guesses.length - 1, (index) => (
        <EmptyRow key={index} />
      ))}
    </>
  )
})
