import { useLayoutEffect, useState } from 'react'

export const useIsScreenMaxWidth = (value: number) => useMediaQuery(`(max-width:`, value)
export const useIsScreenMinWidth = (value: number) => useMediaQuery(`(min-width:`, value)
export const useIsScreenMaxHeight = (value: number) => useMediaQuery(`(max-height:`, value)
export const useIsScreenMinHeight = (value: number) => useMediaQuery(`(min-height:`, value)

const useMediaQuery = (queryPrefix: string, width: number) => {
  const [matches, setMatches] = useState(false)

  useLayoutEffect(() => {
    const listener = window.matchMedia(`${queryPrefix} ${width}px)`)
    const updateMatches = () => setMatches(listener.matches)

    updateMatches()
    listener.addEventListener('change', updateMatches)

    return () => listener.removeEventListener('change', updateMatches)
  }, [queryPrefix, width])

  return matches
}
