export const copyToClipboard = (stringToCopy: string) => {
  if (navigator.clipboard) {
    navigator.clipboard
      .writeText(stringToCopy)
      .then(() => {})
      .catch(() => {})
  } else {
    const $textarea = document.createElement('textarea')
    $textarea.value = stringToCopy
    document.body.appendChild($textarea)
    $textarea.select()
    document.execCommand('copy')
    document.body.removeChild($textarea)
  }
}
