import { observer } from 'mobx-react-lite'
import { formatDate } from '../lib/date-time'
import { state } from '../state'

export const DailyBoxSmall = observer(() => {
  return (
    <p
      className="fs-lg fw-bold text-body position-absolute"
      style={{
        width: 200,
        top: 87,
        left: -120,
        whiteSpace: 'nowrap',
        transform: 'rotate(90deg)',
        opacity: 0.7,
      }}
    >
      Daily Puzzle #{state.dailyId}{' '}
      <span className="ms-2 fs-sm" style={{ opacity: 0.7 }}>
        Game ID: {state.gameId}
      </span>
    </p>
  )
})

export const DailyBoxBig = observer(() => {
  return (
    <div className="mx-auto" style={{ maxWidth: 360 }}>
      <div className="card mb-4">
        <div className="card-body">
          <h6 className="card-title fs-lg">Daily Puzzle #{state.dailyId}</h6>
          <p className="card-text fs-md mb-2">{formatDate(new Date())}</p>
          <p className="card-text fs-md text-body">
            A new word is selected every day at midnight Eastern Time
          </p>
        </div>
      </div>
    </div>
  )
})
