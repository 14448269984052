import { useEffect, useState } from 'react'
import classNames from 'classnames'
import { CharStatus } from '../../../lib/statuses'
import './styles.scss'
import { state } from '../../../state'

const CELL_FLIP_DURATION_MS = 500
export const getFlipDurationTotalMs = () => state.isSpeedMode ? 0 : CELL_FLIP_DURATION_MS * state.letterCount + 500

export const FlipCell = ({
  value,
  status,
  flipIndex,
}: {
  value?: string
  status?: CharStatus
  flipIndex?: number
}) => {
  const [isFlipping, setIsFlipping] = useState(false)

  useEffect(() => {
    if (typeof flipIndex !== 'number') return
    setTimeout(() => setIsFlipping(true), flipIndex * (state.isSpeedMode ? 0 : CELL_FLIP_DURATION_MS))
  }, [flipIndex])

  return (
    <div className={classNames('flip-container', { 'flip-hover': isFlipping })}>
      <div className={classNames('flipper', { 'flipper-speedy': state.isSpeedMode })}>
        <div className="flip-front">
          <div>{value}</div>
        </div>
        <div className={`flip-back wp-cell-${status}`}>
          <div>{value}</div>
        </div>
      </div>
    </div>
  )
}
