import { observable, runInAction } from 'mobx'
import { darkModeStorage, highContrastModeStorage, keyboardLayoutStorage, speedModeStorage } from './lib/localStorage'
import { Analytics } from './lib/analytics'

type StateT = {
  solution: string
  challengeId?: string
  challengeName?: string
  challengeCreatorName?: string
  playerName?: string
  letterCount: number
  guessCount: number
  gameType: string
  mode: 'daily' | 'random'
  gameId: string
  dailyId: string
  isGameWon: boolean
  isGameLost: boolean
  score: number | null
  guesses: string[]
  currentGuess: (string | null)[]
  currentGuessString: string
  cursorIndex: number
  isDarkMode: boolean
  isHighContrastMode: boolean
  keyboardLayout?: string
  isSpeedMode: boolean
  userToken?: string
  isLoggedIn?: boolean
  listName?: string
  mechanism: string
  description?: string
  heading?: string
  footer?: string
  customTypeId?: string
}

export const state = observable<StateT>({
  solution: '',
  challengeId: undefined,
  challengeName: undefined,
  challengeCreatorName: undefined,
  playerName: undefined,
  letterCount: 5,
  guessCount: 6,
  gameType: 'core',
  mode: 'daily',
  gameId: '',
  dailyId: '',
  isGameWon: false,
  isGameLost: false,
  score: null,
  guesses: [],
  currentGuess: [],
  get currentGuessString(): string {
    return this.currentGuess.filter((i) => i !== null).join('')
  },
  cursorIndex: 0,
  isDarkMode: !!darkModeStorage.get().isDark,
  isHighContrastMode: !!highContrastModeStorage.get().isHighContrast,
  keyboardLayout: keyboardLayoutStorage.get().keyboardLayout,
  isSpeedMode: !!speedModeStorage.get().isSpeedMode,
  userToken: undefined,
  listName: undefined,
  mechanism: 'core',
  description: undefined,
  heading: undefined,
  footer: undefined,
  customTypeId: undefined
})

export const setState = (newState: Partial<StateT>) => {
  runInAction(() => {
    Object.assign(state, newState)

    if (newState?.isGameLost === true) {
      Analytics.tag('Game - Lost')
    } else if (newState?.isGameWon === true) {
      Analytics.tag('Game - Won')
    }

    if (typeof newState.isDarkMode === 'boolean') {
      darkModeStorage.set({ isDark: newState.isDarkMode })
    }

    if (typeof newState.isHighContrastMode === 'boolean') {
      highContrastModeStorage.set({ isHighContrast: newState.isHighContrastMode })
    }

    if (typeof newState.keyboardLayout === 'string') {
      keyboardLayoutStorage.set({ keyboardLayout: newState.keyboardLayout })
    }

    if (typeof newState.isSpeedMode === 'boolean') {
      speedModeStorage.set({ isSpeedMode: newState.isSpeedMode })
    }
  })
}
