import { matchPath } from 'react-router-dom'

export const WORDLE_TITLE = 'WordPlay - The Totally Unofficial Wordle Practice App'
export const WORDLE_TITLE_FOR_SHARE = 'WordPlay.com '
export const WORDLE_TITLE_FOR_CHALLENGE = (isWin: boolean, guessesLength: number) =>
  isWin
    ? `I solved a WordPlay in ${guessesLength} out of 6 turns.`
    : `I couldn't solve this WordPlay in 6 turns.`

export const WIN_MESSAGES = ['Great Job!', 'Awesome', 'Well done!']
export const GAME_COPIED_MESSAGE = 'Game copied to clipboard'
export const ABOUT_GAME_MESSAGE = 'About this game'
export const ONLY_LETTERS_ALLOWED_MESSAGE = 'You have to have letters for each spot'
export const FLUID_GAME_GUESS_TOO_SHORT_MESSAGE = 'Your word guess must be at least 4 letters long'
export const WORD_NOT_FOUND_MESSAGE = (word: string) => `${word} is not a valid word`
export const CORRECT_WORD_MESSAGE = (solution: string) => `The word was ${solution}`
export const GUESS_DISTRIBUTION_TEXT = 'Guess Distribution'
export const NEW_WORD_TEXT = 'New word in'
export const SHARE_TEXT = 'Share'
export const TOTAL_TRIES_TEXT = 'Total tries'
export const SUCCESS_RATE_TEXT = 'Success rate'
export const CURRENT_STREAK_TEXT = 'Current streak'
export const BEST_STREAK_TEXT = 'Best streak'
export const isBaseGameplayRoute = (pathname: string) =>
  [
    '/',
    '/new',
    '/daily',
    '/challenge/play',
    '/game/:gameType',
    '/custom/:customTypeId/:customTypeName',
    '/unlimited',
  ].some((route) => !!matchPath(route, pathname))
