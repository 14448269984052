import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { API_ARTICLE } from '../../../constants/api'
import { Helmet } from 'react-helmet'
import './styles.scss'
import { api } from '../../../lib/api'

export const ArticlePage = () => {
  const params = useParams()
  const [article, setArticle] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    ;(async () => {
      if (!params?.id) return

      setIsLoading(true)
      try {
        const response = await api.get(API_ARTICLE, {
          params: {
            article_id: params.id,
          },
        })
        setArticle(response?.data?.body)
      } catch {}
      setIsLoading(false)
    })()
  }, [params.id])

  const title = parseArticleTitle(article)

  return (
    <div className="wordplay-blog py-5 px-4 mx-auto" style={{ maxWidth: 800 }}>
      <Helmet>{title && <title>{title} | WordPlay</title>}</Helmet>

      {isLoading ? (
        <div className="d-flex pt-5 justify-content-center">
          <div className="spinner-border" />
        </div>
      ) : (
        <div dangerouslySetInnerHTML={{ __html: article || 'Nothing to see here.' }} />
      )}
    </div>
  )
}

const parseArticleTitle = (html: string) => html.match(/<h1>(.+)<\/h1>/)?.[1]
