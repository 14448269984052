import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { unlimitedWelcomeStorage } from '../../lib/localStorage'

export const UnlimitedWelcomePage = () => {
  useEffect(() => {
    unlimitedWelcomeStorage.set({ seen: true })
  }, [])

  return (
    <div className="py-5 overflow-hidden">
      <div className="position-relative mx-auto px-4" style={{ maxWidth: 800 }}>
        <div className="text-center pb-4">
          <h1>Welcome!</h1>
        </div>

        <div>
          <div>
            <p className="text-body">
              You're probably wondering why you wound up on this site (
              <Link to="/" target="_blank">
                WordPlay.com
              </Link>
              ) when you meant to go to WordleUnlimited.com.
            </p>

            <p className="text-body">
              Sadly, the person running WordleUnlimited.com had to shut down the site, and rather
              than leave everyone hanging, he thought it better to send them here to WordPlay.
            </p>

            <p className="text-body">
              For more details, read{' '}
              <Link to="/article/unlimited" target="_blank">
                this article
              </Link>
              . In the meantime, enjoy!
            </p>
            <p className="text-body">
              <a href="https://twitter.com/dharmesh" target="_blank" rel="noreferrer">
                @dharmesh
              </a>
              , the guy behind{' '}
              <Link to="/" target="_blank">
                WordPlay.com
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
