import { useEffect, useState } from 'react'
import { howToPlayStorage } from '../../lib/localStorage'
import { FlipCell } from '../grid/Cell/FlipCell'
import { api } from '../../lib/api'
import { API_FAQ } from '../../constants/api'

export const FAQPage = () => {

  const [questions, setQuestions] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    ;(async () => {
      
      setIsLoading(true)
      try {
        const response = await api.get(API_FAQ)
        console.log(response)
        setQuestions(response?.data || [])
      } catch {}
      setIsLoading(false)
    })()
  }, [])

  return (
    <div className="mx-auto py-5 px-4" style={{ maxWidth: 600 }}>
      <h1 className='text-center'>FAQ</h1>
      {questions.map((question: any, i: number) => (
        <div className="card mb-3">
          <div className="card-body">
            <h5 className="card-title mb-0"> <a href={question?.link} style={{textDecoration: 'none'}}>{question?.question}</a></h5>
          </div>
        </div>
      ))}
    </div>
  )
}
