import { useState } from 'react'
import { API_SECOND_WORD_SUGGESTIONS } from '../../constants/api'
import { api } from '../../lib/api'
import { notification } from '../Notification'

export const SecondWordPage = () => {
  const [value, setValue] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [suggestions, setSuggestions] = useState<string[]>([])

  const handleSubmit = async () => {
    if (!value.trim()) return
    setIsSubmitting(true)
    try {
      const response = await api.get(API_SECOND_WORD_SUGGESTIONS, {
        params: { word: value.trim() },
      })
      if (response.data.error_message) {
        notification.danger({ title: response.data.error_message })
        setIsSubmitting(false)
        return
      }
      setSuggestions(response.data.suggestions || [])
    } catch (error: any) {
      notification.danger({ title: error.message })
    }
    setIsSubmitting(false)
  }

  return (
    <div className="mx-auto px-4 pt-5" style={{ maxWidth: 600 }}>
      <h1>SecondWord For Wordle</h1>
      <p className="text-body">
        Already have your favorite first word? Find some great pairings of second words.
      </p>

      <div className="input-group mb-5">
        <input
          className="form-control"
          autoFocus
          type="text"
          name="word"
          placeholder="Enter Word"
          onChange={(e) => setValue(e.target.value)}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              handleSubmit()
            }
          }}
          value={value}
        />
        <button
          className="btn btn-primary"
          type="button"
          onClick={handleSubmit}
          disabled={isSubmitting}
        >
          {isSubmitting && <span className="spinner-border spinner-border-sm me-2" />}
          Grade
        </button>
      </div>

      {suggestions.length > 0 && (
        <div>
          <h3>Suggestions</h3>
          {suggestions.map((item, i) => (
            <div className="d-inline-block card py-2 px-3 mt-2 me-2" key={i}>
              <p className="card-text mb-0">{item}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
