type Props = {
  title?: string
  children: React.ReactNode
  maxWidth?: number
  isTextCenter?: boolean
  isOpen: boolean
  handleClose: () => void
}

export const BaseModal = ({ title, children, maxWidth = 600, isOpen, handleClose }: Props) => {
  if (!isOpen) return null

  return (
    <div
      className="wp-modal"
      style={{
        zIndex: 10,
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        overflowY: 'auto',
      }}
    >
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          height: '100%',
          width: '100%',
          background: 'rgba(20, 23, 34, 0.6)',
        }}
        onClick={handleClose}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100%',
          padding: '50px 30px',
        }}
      >
        <div
          className="modal-dialog"
          role="document"
          style={{ display: 'block', maxWidth, width: '100%' }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{title}</h5>
              <button
                type="button"
                className="btn-close"
                onClick={handleClose}
                aria-label="Close"
              />
            </div>
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}
