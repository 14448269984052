import { useEffect } from 'react'
import { howToPlayStorage } from '../../lib/localStorage'
import { FlipCell } from '../grid/Cell/FlipCell'

export const GuidePage = () => {
  useEffect(() => {
    howToPlayStorage.set({ seen: true })
  }, [])

  return (
    <div className="mx-auto py-5 px-4 text-center" style={{ maxWidth: 600 }}>
      <h1>How to play</h1>
      <p className="text-body">
        Try to guess the word in 6 tries. After each guess, the color of the tiles will change to
        show how close your guess was to the word.
      </p>
      <div className="d-flex justify-content-center mb-2 mt-4">
        <FlipCell value="S" status="correct" flipIndex={0} />
        <FlipCell value="M" flipIndex={1} />
        <FlipCell value="I" flipIndex={2} />
        <FlipCell value="L" flipIndex={3} />
        <FlipCell value="E" flipIndex={4} />
      </div>
      <p className="text-body fs-md">The letter S is in the word and in the correct spot.</p>
      <div className="d-flex justify-content-center mb-2 mt-4">
        <FlipCell value="L" flipIndex={0} />
        <FlipCell value="A" flipIndex={1} />
        <FlipCell value="U" status="present" flipIndex={2} />
        <FlipCell value="G" flipIndex={3} />
        <FlipCell value="H" flipIndex={4} />
      </div>
      <p className="text-body fs-md">The letter U is in the word but in the wrong spot.</p>
      <div className="d-flex justify-content-center mb-2 mt-4">
        <FlipCell value="E" flipIndex={0} />
        <FlipCell value="N" flipIndex={1} />
        <FlipCell value="J" flipIndex={2} />
        <FlipCell value="O" status="absent" flipIndex={3} />
        <FlipCell value="Y" flipIndex={4} />
      </div>
      <p className="text-body fs-md">The letter O is not in the word in any spot.</p>

      <p className="text-body fs-lg mt-5">
        Tip: You can press [space] to put a placeholder for a letter you're unsure of.
      </p>
    </div>
  )
}
