import { observer } from 'mobx-react-lite'
import { state } from '../../state'
import { Cell } from './Cell'

export const EmptyRow = observer(() => {
  const emptyCells = Array.from(Array(state.letterCount))

  return (
    <div className="d-flex justify-content-center mb-1">
      {emptyCells.map((_, i) => (
        <Cell key={i} />
      ))}
    </div>
  )
})
