import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { API_GET_END_GAME_MESSAGE } from '../../constants/api'
import { api } from '../../lib/api'
import { state } from '../../state'
import { EndActions } from './EndActions'
import { WordDefinition } from '../WordDefinition'
import './endgame.scss'

export const EndGameBox = observer(() => {
  const [message, setMessage] = useState('')

  useEffect(() => {
    ;(async () => {
      const defaultMessage = state.isGameWon
        ? `You correctly guessed ${state.solution.toUpperCase()}`
        : `The word was ${state.solution.toUpperCase()}`

      try {
        const response = await api.get(API_GET_END_GAME_MESSAGE)
        setMessage(response?.data?.message || defaultMessage)
      } catch {
        setMessage(defaultMessage)
      }
    })()
  }, [])

  return (
    <div className="mx-auto" style={{ maxWidth: 360 }}>
      <div className="d-flex align-items-center mb-4">
        {typeof state.score === 'number' && (
          <div
            className="d-flex flex-column align-items-center justify-content-center me-1 fw-bold rounded text-white"
            style={{
              width: 76,
              height: 76,
              minWidth: 76,
              background: (() => {
                if (state.score < 30) return 'var(--bs-gray-500)'
                if (state.score >= 30 && state.score < 60) return 'var(--wordplay-orange)'
                if (state.score >= 60) return 'var(--wordplay-green)'
              })(),
            }}
          >
            <div style={{ fontSize: 14, marginTop: 5, lineHeight: '14px' }}>Score</div>
            <div style={{ fontSize: 28 }}>{state.score}</div>
          </div>
        )}
        {message && (
          <>
            <div
              className={`wp-message wp-message-${state.isGameWon ? 'success' : 'danger'} w-100 rounded px-3 fw-semibold fs-sm`}
            >
              <div dangerouslySetInnerHTML={{ __html: message }}></div>
            </div>
          </>
        )}
      </div>

      <WordDefinition word={state.solution} />
      <EndActions />
    </div>
  )
})
