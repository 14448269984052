import classNames from 'classnames'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { API_WORD } from '../../../constants/api'
import { notification } from '../../Notification'
import { api } from '../../../lib/api'
import { DictionaryEntryT } from './types'

type DetailsT = {
  definition?: string
  dictonary_entry? : DictionaryEntryT[]
  word: string
  message?: string
}

const entryIsSameWord = (entry: DictionaryEntryT, word: string) => {
  return entry?.meta?.id?.includes(word)
}

export const WordDetailsPage = () => {
  const params = useParams()
  const [value, setValue] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [details, setDetails] = useState<null | DetailsT>(null)

  useEffect(() => {
    if (!params.word) return
    setValue(params.word)
    handleSubmit(params.word)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = async (argValue?: string) => {
    setIsSubmitting(true)
    try {
      const word = argValue?.trim() || value.trim()

      const response: { data: DetailsT } = await api.get(API_WORD, { params: { word } })
      if (response.data.message) {
        notification.danger({ title: response.data.message })
        setIsSubmitting(false)
        return
      }
      setDetails({ ...response.data, word })
    } catch (error: any) {
      notification.danger({ title: error.message })
    }
    setIsSubmitting(false)
  }

  return (
    <div className="mx-auto py-5 px-4" style={{ maxWidth: 700 }}>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <div>
          <h1>Dictionary</h1>
          {details && (
            <h4>
              <strong>{details.word.toUpperCase()}</strong>
            </h4>
          )}
        </div>
        <img
          src="/merriam-webster.png"
          style={{ display: 'block', minWidth: 125, width: 125, height: 125, marginTop: -10 }}
          alt="Merriam Webster"
        />
      </div>

      {details && (
        <>
          {/* {details.definition && (
            <div className="mt-10 mb-8">
              <dt>Main Definition:</dt>
              <dd className="text-body">{details.definition}</dd>
            </div>
          )} */}
          <div>
            {details.dictonary_entry?.filter((entry: DictionaryEntryT) => entryIsSameWord(entry, details.word)).map((entry, i) => (
              <div key={i} className="card mb-4">
                <div className='card-header'>
                  <div className='d-flex align-items-center'>
                    <h2 className='mb-0'>{details.word}</h2>
                    <h4 className='ms-2 mb-0'>({entry.fl})</h4>
                  </div>
                </div>
                <div className="card-body">
                  <ol className='mb-0'>
                    {entry.shortdef?.map((def, j) => (
                      <li>{def}</li>
                    ))}
                  </ol>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  )
}

const getFrequencyClass = (frequency: number) => {
  if (frequency < 3.33) return 'bg-danger'
  if (frequency < 6.66) return 'bg-warning'
  return 'bg-success'
}
