import { observer } from 'mobx-react-lite'
import { useGuessStatuses } from '../../lib/api'
import { state } from '../../state'
import { Cell } from './Cell'
import { FlipCell } from './Cell/FlipCell'

export const CompletedRow = observer(({ guess }: { guess: string }) => {
  const { solution, letterCount } = state
  const statuses = useGuessStatuses({ guess, solution: solution })
  const letterGap = letterCount - guess.length
  const guessAppended = guess + '-'.repeat(letterGap)
  const statusesAppended = statuses?.concat(
    Array(letterGap).fill({ letter: '-', status: 'absent' }),
  )
  return (
    <div className="d-flex justify-content-center mb-1">
      {guessAppended
        .split('')
        .map((letter, i) =>
          statusesAppended?.length === guessAppended.length ? (
            <FlipCell key={i} value={letter} status={statusesAppended[i].status} flipIndex={i} />
          ) : (
            <Cell key={i} value={letter} />
          ),
        )}
    </div>
  )
})
