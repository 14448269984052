import axios, { AxiosRequestConfig, Method } from 'axios'
import { toPairs } from 'lodash'
import { useEffect, useState } from 'react'
import {
  API_BASEURL,
  API_CHALLENGE_RESULTS,
  API_CHECK_GUESS,
  API_GET_CURRENT_GAME_STATE,
  API_GET_GUESS_RESULTS,
  API_GET_USER_TOKEN,
  API_GET_GAME_TYPE,
} from '../constants/api'
import { state, setState } from '../state'
import { userIdStorage } from './localStorage'
import { CharStatus } from './statuses'
import pubsub from 'sweet-pubsub'

export const fetchAndSaveUserToken = async (): Promise<string> => {
  const currentUserToken = userIdStorage.get().userId

  if (currentUserToken) {
    return currentUserToken
  }

  const response = await api.get(API_GET_USER_TOKEN)

  if (!response?.data?.user_token) {
    throw new Error('Token not found in response')
  }

  userIdStorage.set({ userId: response?.data?.user_token })
  return response?.data?.user_token
}

export const fetchFluidGameTypeDetails = async ({
  challengeId,
  mode,
  gameType,
  mechanism,
}: {
  challengeId?: string
  mode: 'daily' | 'random'
  gameType: string
  mechanism: string
}): Promise<any | null> => {
  try {
    const response = await api.get(API_GET_GAME_TYPE, {
      params: { challenge_id: challengeId, mode, game_type: gameType },
    })

    return response?.data
  } catch {
		console.log("error")
    return null
  }
}

export type ChallengeResultsT = {
  challenge_creator_name: string
  challenge_name: string
  solution: string
  players: Array<{
    name: string
    guesses: Array<string>
  }>
}

export const fetchChallengeResults = async (challengeId: string): Promise<ChallengeResultsT> => {
  const response = await api.get(API_CHALLENGE_RESULTS, {
    params: { challenge_id: challengeId },
  })
  setState({
    challengeCreatorName: response?.data?.challenge_creator_name,
    challengeName: response?.data?.challenge_name,
  })
  return {
    challenge_creator_name: response.data.challenge_creator_name,
    challenge_name: response.data.challenge_name,
    solution: response.data.solution,
    players: Object.entries(response.data.players).map(([name, { guesses }]: any) => ({
      name,
      guesses,
    })),
  }
}

export type GuessStatusT = { letter: string; status: CharStatus }

const apiToClientStatusMap: { [key: string]: CharStatus } = {
  miss: 'absent',
  occurs: 'present',
  match: 'correct',
}

export const fetchGuessStatuses = async (
  guess: string,
  solution: string,
): Promise<GuessStatusT[]> => {
  const response = await api.get(API_GET_GUESS_RESULTS, {
    params: { guess: guess.toLowerCase(), solution: solution.toLowerCase() },
  })
  return response.data.letters.map(toPairs).map(([[letter, status]]: any) => ({
    letter,
    status: apiToClientStatusMap[status],
  }))
}

export const isWordInWordList = async (word: string) => {
  const response = await api.get(API_CHECK_GUESS, { params: { word: word.toLowerCase(), custom_type_id: state.customTypeId } })
  return response?.data?.result
}

export const useGuessStatuses = ({ guess, solution }: { guess: string; solution: string }) => {
  const [statuses, setStatuses] = useState<GuessStatusT[] | null>(null)

  useEffect(() => {
    ;(async () => {
      try {
        const response = await fetchGuessStatuses(guess, solution)
        setStatuses(response)
      } catch {}
    })()
  }, [guess, solution])

  return statuses
}

const axiosInstance = axios.create({
  baseURL: API_BASEURL,
  headers: { 'Access-Control-Allow-Origin': '*' },
})

const apiRequest = async (method: Method, url: string, options: AxiosRequestConfig = {}) => {
  const { data = {}, params = {}, ...otherOptions } = options

  const defaultProps = {
    user_token: state.userToken,
    game_id: state.gameId || undefined,
    letter_count: state.letterCount,
    game_type: state.gameType,
  }

  const response = await axiosInstance({
    method,
    url,
    data: method !== 'GET' ? { ...defaultProps, ...data } : undefined,
    params: method === 'GET' ? { ...defaultProps, ...params } : undefined,
    ...otherOptions,
  })

  return response
}

export const api = {
  get: (url: string, options?: AxiosRequestConfig) => apiRequest('GET', url, options),

  post: (url: string, data: any, options?: AxiosRequestConfig) =>
    apiRequest('POST', url, { data, ...options }),

  put: (url: string, data: any, options?: AxiosRequestConfig) =>
    apiRequest('PUT', url, { data, ...options }),

  patch: (url: string, data: any, options?: AxiosRequestConfig) =>
    apiRequest('PATCH', url, { data, ...options }),

  delete: (url: string, data: any, options?: AxiosRequestConfig) =>
    apiRequest('DELETE', url, { data, ...options }),
}
