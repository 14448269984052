import { Link, LinkProps, useLocation } from 'react-router-dom'
import {
  joinPathnameQuery,
  objectToQueryString,
  queryStringToObject,
  splitPathnameQuery,
} from '../lib/url'

export const LinkSaveParams = (props: Omit<LinkProps, 'to'> & { to: string }) => {
  const location = useLocation()
  const currentParams = queryStringToObject(location.search)
  const linkParams = queryStringToObject(splitPathnameQuery(props.to).query)
  const combinedParamsString = objectToQueryString({ ...currentParams, ...linkParams })
  return <Link {...props} to={joinPathnameQuery(props.to, combinedParamsString)} />
}
