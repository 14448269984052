import { useEffect } from 'react'

const win: any = window

export const BottomAd = () => {
  useEffect(() => {
    win.adsbygoogle = win.adsbygoogle || []
    win.adsbygoogle.push({})
  }, [])

  return (
    <div
      className="d-flex align-items-center justify-content-center text-center"
      style={{ width: '100%' }}
    >
      <ins
        className="adsbygoogle"
        data-ad-client="ca-pub-1735348343402057"
        data-ad-slot="9311385792"
        data-ad-format="auto"
        data-full-width-responsive="true"
        style={{ display: 'block', textAlign: 'center', width: '100%' }}
      ></ins>
    </div>
  )
}
