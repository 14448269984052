import { observer } from 'mobx-react-lite'
import { Helmet } from 'react-helmet'
import { state } from '../state'
import { useLocation } from 'react-router-dom'

type metaMappingOptions = {
    [key: string]: any
}

export const MetaTags = observer(() => {
    const getTitleDescriptionBlocks = (location: any) => {
        const { challengeCreatorName, challengeName, mode, dailyId } = state
        const metaMapping : metaMappingOptions = {
            'challengeMode': {
                title: `WordPlay Challenge From ${challengeCreatorName}`,
                description: `You've been challenged by ${challengeCreatorName} to play the challenge ${challengeName}`
            },
            'dailyPuzzle': {
                title: `Daily Puzzle ${dailyId}`,
                description: `Daily Puzzle ${dailyId}`
            },
            'firstword': {
                title: `Find The Best First Word For Wordle | WordPlay`,
                description: `Find The Best First Word For Wordle | WordPlay`
            },
            'stats': {
                title: `Game Stats | WordPlay`,
                description: `See your game stats for WordPlay`
            },

        }

        let metaMappingKey = null
        if (challengeCreatorName && challengeName) {
            metaMappingKey = 'challengeMode';
        } else if (mode === 'daily' && dailyId) {
            metaMappingKey = 'dailyPuzzle';
        } else if (location.pathname === '/firstword'){
            metaMappingKey = 'firstword';
        } else if (location.pathname === '/stats'){
            metaMappingKey = 'stats';
        } else {
            return null
        }

        try {
            if (metaMappingKey !== null) {
                return metaMapping[metaMappingKey];
            } else {
                return null
            }
        } catch (error) {
            return null
        }
    }

    const location = useLocation()
    const dataBlock = getTitleDescriptionBlocks(location)

    return !dataBlock ? null : (
      <Helmet>
        {/* <title>{dataBlock?.title} | WordPlay</title> */}
        <title>WordPlay: The Unofficial Wordle Unlimited Practice Game</title>
        <meta property="og:title" content="WordPlay: The Unofficial Wordle Unlimited Practice Game" />
        <meta property="og:description" content={dataBlock?.description} />
      </Helmet>
    )
  })
  