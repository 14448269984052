import { useCallback, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { RefreshIcon } from '@heroicons/react/outline'
import { notification } from '../Notification'
import { Spinner } from '../Spinner'
import { ChallengeResultsT, fetchChallengeResults, useGuessStatuses } from '../../lib/api'
import { state } from '../../state'
import { FlipCell } from '../grid/Cell/FlipCell'

export const ChallengeResultsPage = observer(() => {
  const { challengeId } = state
  const [results, setResults] = useState<null | ChallengeResultsT>(null)
  const [isRefreshing, setIsRefreshing] = useState(false)

  const fetchResults = useCallback(async () => {
    if (!challengeId) return
    setIsRefreshing(true)
    try {
      const response = await fetchChallengeResults(challengeId)
      setResults(response)
    } catch (error: any) {
      notification.danger({ title: error.message })
    }
    setIsRefreshing(false)
  }, [challengeId])

  useEffect(() => {
    fetchResults()
    const interval = setInterval(fetchResults, 1000 * 15)
    return () => clearInterval(interval)
  }, [fetchResults])

  if (!challengeId) return <div className="text-center">Challenge ID is missing from URL</div>

  return (
    <div className="mx-auto py-5 px-4 text-center" style={{ maxWidth: 600 }}>
      <h1>Challenge Results</h1>

      <p className="d-flex align-items-center justify-content-center ">
        <div className="fw-semibold text-body fs-lg">{results?.challenge_name}</div>
        <button
          type="button"
          className="btn btn-primary btn-sm d-flex align-items-center ms-3"
          onClick={fetchResults}
        >
          Refresh
          {isRefreshing ? (
            <div className="spinner-border spinner-border-sm ms-2" />
          ) : (
            <RefreshIcon style={{ marginLeft: 6, width: 18 }} />
          )}
        </button>
      </p>
      {!results && (
        <div className="d-flex pt-5 justify-content-center">
          <div className="spinner-border" />
        </div>
      )}

      {results?.players?.map((player, i) => (
        <div key={i}>
          <div className="fw-medium fs-lg mt-5 mb-3">{player.name}</div>
          {player.guesses.map((guess, j) => (
            <Guess key={j} guess={guess} solution={results.solution} />
          ))}
        </div>
      ))}
    </div>
  )
})

export const Guess = ({ guess, solution }: { guess: string; solution: string }) => {
  const statuses = useGuessStatuses({ guess, solution })

  return (
    <div className="d-flex justify-content-center mb-2">
      {guess.split('').map((letter, i) => (
        <FlipCell
          key={i}
          value={letter.toUpperCase()}
          status={statuses?.[i]?.status}
          flipIndex={i}
        />
      ))}
    </div>
  )
}
