import { KeyValue } from '../../../lib/keyboard'
import { Key } from '../Key'
import { observer } from 'mobx-react-lite'

const AlphabeticKeyboard = observer(
  ({
    onClick,
    charStatuses
  }: {
    onClick: (value: KeyValue) => void
    charStatuses: any
  }) => {

    return (
      <div className="mb-4">
        <div className="d-flex justify-content-center mb-1">
          <Key value="A" onClick={onClick} status={charStatuses['A']} />
          <Key value="B" onClick={onClick} status={charStatuses['B']} />
          <Key value="C" onClick={onClick} status={charStatuses['C']} />
          <Key value="D" onClick={onClick} status={charStatuses['D']} />
          <Key value="E" onClick={onClick} status={charStatuses['E']} />
          <Key value="F" onClick={onClick} status={charStatuses['F']} />
          <Key value="G" onClick={onClick} status={charStatuses['G']} />
          <Key value="H" onClick={onClick} status={charStatuses['H']} />
          <Key value="I" onClick={onClick} status={charStatuses['I']} />
          <Key value="J" onClick={onClick} status={charStatuses['J']} />
        </div>
        <div className="d-flex justify-content-center mb-1">
          <Key value="K" onClick={onClick} status={charStatuses['K']} />
          <Key value="L" onClick={onClick} status={charStatuses['L']} />
          <Key value="M" onClick={onClick} status={charStatuses['M']} />
          <Key value="N" onClick={onClick} status={charStatuses['N']} />
          <Key value="O" onClick={onClick} status={charStatuses['O']} />
          <Key value="P" onClick={onClick} status={charStatuses['P']} />
          <Key value="Q" onClick={onClick} status={charStatuses['Q']} />
          <Key value="R" onClick={onClick} status={charStatuses['R']} />
          <Key value="S" onClick={onClick} status={charStatuses['S']} />
        </div>
        <div className="d-flex justify-content-center mb-1">
          <Key value="ENTER" onClick={onClick}>
            ENTER
          </Key>
          <Key value="T" onClick={onClick} status={charStatuses['T']} />
          <Key value="U" onClick={onClick} status={charStatuses['U']} />
          <Key value="V" onClick={onClick} status={charStatuses['V']} />
          <Key value="W" onClick={onClick} status={charStatuses['W']} />
          <Key value="X" onClick={onClick} status={charStatuses['X']} />
          <Key value="Y" onClick={onClick} status={charStatuses['Y']} />
          <Key value="Z" onClick={onClick} status={charStatuses['Z']} />
          <Key value="DELETE" onClick={onClick}>
            DELETE
          </Key>
        </div>
        <div className="d-flex justify-content-center">
          <Key value="<" onClick={onClick} />
          <Key value="SPACE" onClick={onClick}>
            SPACE
          </Key>
          <Key value=">" onClick={onClick} />
        </div>
      </div>
    )
  }
)

export default AlphabeticKeyboard