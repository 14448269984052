import { LoginButtons } from '../LoginButtons'
import {
  ChartBarIcon,
  ClipboardListIcon,
  CogIcon,
  InformationCircleIcon,
  PuzzleIcon,
  QuestionMarkCircleIcon,
  UserGroupIcon,
  MoonIcon,
  SunIcon
} from '@heroicons/react/outline'
import { LinkSaveParams } from '../LinkSaveParams'
import { Link } from 'react-router-dom'
import { setState, state } from '../../state'
import { observer } from 'mobx-react-lite'
import { newGameURL } from '../../lib/url'
import './navigation.scss'

type NavigationItemT = {
  name: string
  to: string
  icon: any
  onClick?: any
}

export const Navigation = observer(() => {
  const aboutNavigation: NavigationItemT[] = [
    {
      name: 'How Do I Play?',
      to: '/guide',
      icon: <InformationCircleIcon style={{ width: 20, height: 20, marginRight: 6 }} />,
    },
    {
      name: 'How Does Scoring Work?',
      to: '/article/scoring',
      icon: <InformationCircleIcon style={{ width: 20, height: 20, marginRight: 6 }} />,
    },

    {
      name: 'What Is This?',
      to: '/about',
      icon: <QuestionMarkCircleIcon style={{ width: 20, height: 20, marginRight: 6 }} />,
    },
    {
      name: 'Why Build This?',
      to: '/article/why',
      icon: <QuestionMarkCircleIcon style={{ width: 20, height: 20, marginRight: 6 }} />,
    },
    // {
    //   name: 'FAQ',
    //   to: '/faq',
    //   icon: <QuestionMarkCircleIcon style={{ width: 20, height: 20, marginRight: 6 }} />,
    // },
    // {
    //   name: 'Community',
    //   to: '#',
    //   onClick: () => window.open('https://community.wordplay.com'),
    //   icon: <UserGroupIcon style={{ width: 20, height: 20, marginRight: 6 }} />,
    // },
  ]
  const gamesNavigation: NavigationItemT[] = [
    {
      name: '5-Letter Daily',
      to: '#',
      icon: <PuzzleIcon style={{ width: 20, height: 20, marginRight: 6 }} />,
      onClick: () => (window.location.href = '/daily'),
    },
    {
      name: '5-Letter Classic',
      to: '#',
      icon: <PuzzleIcon style={{ width: 20, height: 20, marginRight: 6 }} />,
      onClick: () => (window.location.href = '/new'),
    },
    {
      name: '6-Letter Game',
      to: '#',
      icon: <PuzzleIcon style={{ width: 20, height: 20, marginRight: 6 }} />,
      onClick: () => (window.location.href = '/game/6letter'),
    },
    {
      name: 'FirstWord',
      to: '/firstword',
      icon: <ClipboardListIcon style={{ width: 20, height: 20, marginRight: 6 }} />,
    },
    {
      name: 'SecondWord',
      to: '/secondword',
      icon: <ClipboardListIcon style={{ width: 20, height: 20, marginRight: 6 }} />,
    },
  ]
  const otherNavigation: NavigationItemT[] = [
    {
      name: 'Stats',
      to: '/stats',
      icon: <ChartBarIcon style={{ width: 20, height: 20, marginRight: 6 }} />,
    },
    {
      name: 'FAQ',
      to: '/faq',
      icon: <QuestionMarkCircleIcon style={{ width: 20, height: 20, marginRight: 6 }} />,
    },
  ]

  return (
    <header className="navbar navbar-expand-lg navbar-dark wp-navigation">
      <div className="container px-0 px-xl-3">
        <LinkSaveParams to="/" className="navbar-brand order-lg-1 me-0 pe-lg-2 me-lg-2">
          <img style={{ height: 40 }} src="/wordplay-logo.png" alt="WordPlay Game" />
        </LinkSaveParams>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse1"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse order-lg-2" id="navbarCollapse1">
          <ul className="navbar-nav me-auto">
            <li className="nav-item dropdown">
              <div
                className="nav-link dropdown-toggle d-flex"
                style={{ cursor: 'default', padding: '14px 12px' }}
                data-bs-toggle="dropdown"
              >
                <InformationCircleIcon style={{ width: 20, height: 20, marginRight: 6 }} />
                About
              </div>
              <ul className="dropdown-menu">
                {aboutNavigation.map((item) => (
                  <li key={item.name}>
                    <LinkSaveParams
                      key={item.name}
                      to={item.to}
                      onClick={item.onClick}
                      className="dropdown-item d-flex"
                    >
                      {item.icon}
                      {item.name}
                    </LinkSaveParams>
                  </li>
                ))}
              </ul>
            </li>

            <li className="nav-item dropdown">
              <div
                className="nav-link dropdown-toggle d-flex"
                style={{ cursor: 'default', padding: '14px 12px' }}
                data-bs-toggle="dropdown"
              >
                <PuzzleIcon style={{ width: 20, height: 20, marginRight: 6 }} />
                Games
              </div>
              <ul className="dropdown-menu">
                {gamesNavigation.map((item) => (
                  <li key={item.name}>
                    <LinkSaveParams
                      key={item.name}
                      to={item.to}
                      onClick={item.onClick}
                      className="dropdown-item d-flex"
                    >
                      {item.icon}
                      {item.name}
                    </LinkSaveParams>
                  </li>
                ))}
              </ul>
            </li>

            {otherNavigation.map((item) => (
              <li className="nav-item" key={item.name}>
                <LinkSaveParams
                  key={item.name}
                  to={item.to}
                  onClick={item.onClick}
                  className="nav-link d-flex"
                  style={{ padding: '14px 12px' }}
                >
                  {item.icon}
                  {item.name}
                </LinkSaveParams>
              </li>
            ))}
          </ul>

          <Buttons />
        </div>
      </div>
    </header>
  )
})

const Buttons = observer(() => {
  return (
    <>
      {/* {state.isDarkMode ? (
        <SunIcon
          className="me-2 my-2 wp-toggle-dark-mode"
          style={{ width: 20 }}
          onClick={() => setState({ isDarkMode: false })}
        />
      ) : (
        <MoonIcon
          className="me-2 my-2 wp-toggle-dark-mode"
          style={{ width: 20 }}
          onClick={() => setState({ isDarkMode: true })}
        />
      )} */}
      <button
        type="button"
        className="btn btn-sm btn-success me-2 my-2"
        onClick={() => {
          window.location.href = newGameURL()
        }}
      >
        New Game
      </button>
      <Link to="/challenge/new" className="me-2 my-2">
        <button type="button" className="btn btn-sm btn-primary">
          Group Challenge
        </button>
      </Link>
      <Link to="/user/settings" className="me-2 my-2">
        <button type="button" className="btn btn-sm btn-outline-light d-flex align-items-center">
          <div className="text-truncate" style={{ maxWidth: 55 }}>
            {state.playerName}
          </div>
          <CogIcon className="ms-1" style={{ width: 20 }} />
        </button>
      </Link>
      <LoginButtons className="btn btn-sm btn-outline-light me-2 my-2" />
    </>
  )
})
