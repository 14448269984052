import HubspotForm from 'react-hubspot-form'

export const SupportPage = () => {
  return (
    <div className="py-5">
      <div className="mx-auto px-4" style={{ maxWidth: 600 }}>
        <HubspotForm
          region="na1"
          portalId="21490653"
          formId="54b69e0c-61b4-4759-99ce-72da45f2b498"
          onSubmit={() => console.log('Submit!')}
          onReady={(form) => console.log('Form ready!')}
          loading={<div>Loading...</div>}
        />
      </div>
    </div>
  )
}
