import { userIdStorage, userProfileStorage } from '../../lib/localStorage'
import { Analytics } from '../../lib/analytics'
import { setState, state } from '../../state'
import { useAuth0 } from '@auth0/auth0-react'
import { useState, useEffect } from 'react'

export const LoginPage = () => {
  const { user, isAuthenticated } = useAuth0()
  const { loginWithRedirect } = useAuth0()
  const { logout } = useAuth0()

  const user_token = userIdStorage.get().userId
  const [isSignedIn, setIsSignedIn] = useState(
    false || state.isLoggedIn || isAuthenticated || user?.email !== undefined,
  )

  const doLogin = () => {
    Analytics.tag('Login')
    loginWithRedirect()
  }

  const doLogout = () => {
    userProfileStorage.set({ data: null })
    setIsSignedIn(false)
    setState({ isLoggedIn: false })
    logout({ returnTo: window.location.origin })
    Analytics.tag('Login - Google Logout')
  }

  useEffect(() => {
    if (!isAuthenticated) {
      doLogin()
    }
    setIsSignedIn(!!isAuthenticated)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])

  return (
    <div className="mx-auto py-5 px-4" style={{ maxWidth: 600 }}>
      <h1 className="mb-4">Login</h1>
      {!isSignedIn ? (
        <button className="btn btn-primary" type="button" onClick={() => doLogin()}>
          Log In
        </button>
      ) : (
        <button className="btn btn-outline-primary" type="button" onClick={() => doLogout()}>
          Logout
        </button>
      )}
      {user_token && <div className="mt-3">User Token: {user_token}</div>}
    </div>
  )
}
