import { useState, useEffect } from 'react'
import { generateEmojiGrid } from '../../lib/share'
import { WORDLE_TITLE_FOR_CHALLENGE, WORDLE_TITLE_FOR_SHARE } from '../../constants/strings'
import { API_CREATE_CHALLENGE, API_SYS_LOG_EVENT } from '../../constants/api'
import { copyToClipboard } from '../../lib/clipboard'
import { BaseModal } from '../modals/BaseModal'
import { state } from '../../state'
import { observer } from 'mobx-react-lite'
import { api } from '../../lib/api'
import { newGameURL } from '../../lib/url'
import { notification } from '../Notification'

type ChallengeT = {
  challenge_id: number
  challenge_name: string
  challenge_url: string
  share_url: string
  solution: string
}

export const EndActions = observer(() => {
  const { guesses, solution, gameId, dailyId, isGameLost } = state

  const [challenge, setChallenge] = useState<null | ChallengeT>(null)
  const [isLoadingChallengeUrl, setIsLoadingChallengeUrl] = useState(false)
  const [isChallengeModalOpen, setIsChallengeModalOpen] = useState(false)
  const [isCopiedChallenge, setIsCopiedChallenge] = useState(false)
  const [isCopiedChallengeSmall, setIsCopiedChallengeSmall] = useState(false)
  const [isCopiedShare, setIsCopiedShare] = useState(false)

  useEffect(() => {
    (async () => {
      setIsLoadingChallengeUrl(true)
      try {
        const response = await api.get(API_CREATE_CHALLENGE, { params: { word: solution } })
        setChallenge(response?.data)
      } catch {}
      setIsLoadingChallengeUrl(false)
    })()
  }, [])

  const handleChallengeFriend = async () => {
    setIsChallengeModalOpen(true)
  }

  const startNewGame = () => {
    window.location.href = newGameURL()
  }

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.code === 'Enter') {
        startNewGame()
      }
    }
    window.addEventListener('keyup', listener)
    return () => {
      window.removeEventListener('keyup', listener)
    }
  }, [])

  const handleShare = async () => {
    const daily = dailyId ? `Daily Puzzle #${dailyId} ` : ''
    const len = isGameLost ? 'X' : guesses.length
    const score = state.score ? `Score: ${state.score} ${len}/${state.guessCount}` : ''
    const emojis = generateEmojiGrid(guesses, solution)
    copyToClipboard(
      `${WORDLE_TITLE_FOR_SHARE} ${daily}\n${score}\n\n${emojis}\n\nPlay the game:\n${challenge?.share_url}`,
    )
    setIsCopiedShare(true)
    setTimeout(() => setIsCopiedShare(false), 2000)
    notification.success({ title: 'Game stats copied.', message: 'Ready to paste.' })
    await api.get(API_SYS_LOG_EVENT, { params: { event: 'share_button', user_token: state.userToken } })
  }

  return (
    <div className="d-flex flex-wrap justify-content-between mb-4">
      <button
        type="button"
        className="btn btn-sm btn-success"
        onClick={startNewGame}
      >
        New Game
      </button>
      <button
        type="button"
        className="btn btn-sm btn-primary"
        onClick={handleShare}
        id="share_button"
      >
        {isCopiedShare ? 'Copied' : 'Share'}
      </button>
      <button type="button" className="btn btn-sm btn-primary" onClick={handleChallengeFriend}>
        Challenge A Friend
      </button>
      <BaseModal
        title="Challenge A Friend"
        isOpen={isChallengeModalOpen}
        handleClose={() => setIsChallengeModalOpen(false)}
      >
        <div className="modal-body text-center">
          <div>
            <div className="mb-3 text-body">
              {WORDLE_TITLE_FOR_CHALLENGE(!isGameLost, guesses.length)}
            </div>
            <pre className="mb-3">{generateEmojiGrid(guesses, solution)}</pre>
          </div>
          <div className="mb-3 text-body">{`See how you do on the same word I played:`}</div>
          <div
            style={{
              verticalAlign: 'middle',
              borderRadius: 6,
              background: 'var(--bs-gray-200)',
              padding: '12px 16px',
              wordBreak: 'break-word',
            }}
          >
            {challenge?.challenge_url}{' '}
            <button
              type="button"
              className="btn btn-sm btn-primary d-inline-flex align-items-center"
              style={{ padding: '1px 6px' }}
              disabled={isLoadingChallengeUrl}
              onClick={() => {
                copyToClipboard(challenge?.challenge_url!)
                setIsCopiedChallengeSmall(true)
                setTimeout(() => setIsCopiedChallengeSmall(false), 2000)
              }}
            >
              {isLoadingChallengeUrl && <div className="spinner-border spinner-border-sm me-2" />}
              {isCopiedChallengeSmall ? 'Copied' : 'Copy'}
            </button>
          </div>

          <div className="d-flex align-items-center justify-content-center mt-4">
            <button
              type="button"
              className="btn btn-sm btn-primary me-2 d-inline-flex align-items-center"
              disabled={isLoadingChallengeUrl}
              onClick={() => {
                const title = WORDLE_TITLE_FOR_CHALLENGE(!isGameLost, guesses.length)
                const emojis = generateEmojiGrid(guesses, solution)
                const cta = `See how you do on the same word I played: ${challenge?.challenge_url!}`
                copyToClipboard(`${title}\n\n${emojis}\n\n${cta}`)
                setIsCopiedChallenge(true)
                setTimeout(() => setIsCopiedChallenge(false), 2000)
              }}
            >
              {isLoadingChallengeUrl && <div className="spinner-border spinner-border-sm me-2" />}
              {isCopiedChallenge ? 'Copied' : 'Copy To Clipboard'}
            </button>
            <a href={challenge?.challenge_url ? challenge.challenge_url.replace('/play', '/results') : '#'}>
              <button disabled={!challenge?.challenge_url} className="btn btn-sm btn-primary">
                Watch Live
              </button>
            </a>
          </div>
        </div>
      </BaseModal>
    </div>
  )
})
