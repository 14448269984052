import { observer } from 'mobx-react-lite'
import { state } from '../state'

export const GameHeadingDescription = observer(() => {
  if (!state.heading && !state.description) return null

  return (
    <div className="card mb-4">
      <div className="card-body">
        <h6 className="card-title">{state.heading}</h6>
        <p className="card-text fs-md text-body">{state.description}</p>
      </div>
    </div>
  )
})
