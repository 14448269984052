import { useLocation } from 'react-router-dom'
import { KeyValue } from '../../lib/keyboard'
import { CharStatus, getStatuses } from '../../lib/statuses'
import { useEffect, useState } from 'react'
import { isBaseGameplayRoute } from '../../constants/strings'
import { getFlipDurationTotalMs } from '../grid/Cell/FlipCell'
import { IS_LOCAL_DEV } from '../../constants/api'
import { state } from '../../state'
import { observer } from 'mobx-react-lite'
import KeyboardLayout from './layout'

export const Keyboard = observer(
  ({
    onChar,
    onDelete,
    onEnter,
    onSpace,
    onLeft,
    onRight,
  }: {
    onChar: (value: string) => void
    onDelete: () => void
    onEnter: () => void
    onSpace: () => void
    onLeft: () => void
    onRight: () => void
  }) => {
    const { solution, guesses, keyboardLayout } = state
    const location = useLocation()
    const [isFlipping, setIsFlipping] = useState(false)
    const [charStatuses, setCharStatuses] = useState<{ [key: string]: CharStatus }>({})

    useEffect(() => {
      if (guesses.length === 0 || IS_LOCAL_DEV) return
      setIsFlipping(true)
      setTimeout(() => setIsFlipping(false), getFlipDurationTotalMs())
    }, [guesses])

    useEffect(() => {
      setTimeout(() => {
        setCharStatuses(getStatuses(guesses, solution))
      }, getFlipDurationTotalMs())
    }, [guesses, solution])

    useEffect(() => {
      const listener = (e: KeyboardEvent) => {
        if (
          !isBaseGameplayRoute(location.pathname) ||
          isFlipping ||
          isFocusedElementEditable() ||
          state.isGameLost ||
          state.isGameWon
        )
          return

        if (e.code === 'Space') {
          onSpace()
        } else if (e.code === 'Enter') {
          onEnter()
        } else if (e.code === 'Backspace') {
          onDelete()
        } else if (e.code === 'ArrowLeft') {
          onLeft()
        } else if (e.code === 'ArrowRight') {
          onRight()
        } else {
          const key = e.key.toUpperCase()
          if (key.length === 1 && key >= 'A' && key <= 'Z') {
            onChar(key)
          }
        }
      }
      const preventSpacebarScroll = (e: KeyboardEvent) => {
        if (e.code === 'Space' && isBaseGameplayRoute(location.pathname)) {
          e.preventDefault()
        }
      }
      window.addEventListener('keydown', preventSpacebarScroll)
      window.addEventListener('keyup', listener)
      return () => {
        window.removeEventListener('keydown', preventSpacebarScroll)
        window.removeEventListener('keyup', listener)
      }
    }, [onSpace, onEnter, onDelete, onChar, onLeft, onRight, location, isFlipping])

    const onClick = (value: KeyValue) => {
      if (state.isGameLost || state.isGameWon) return

      if (value === 'ENTER') {
        onEnter()
      } else if (value === 'DELETE') {
        onDelete()
      } else if (value === 'SPACE') {
        onSpace()
      } else if (value === '<') {
        onLeft()
      } else if (value === '>') {
        onRight()
      } else {
        onChar(value)
      }
    }

    return (
      <KeyboardLayout type={keyboardLayout} onClick={onClick} charStatuses={charStatuses} />
    )
  },
)

const isFocusedElementEditable = () =>
  !!document!.activeElement!.getAttribute('contenteditable') ||
  ['TEXTAREA', 'INPUT'].includes(document!.activeElement!.tagName)
