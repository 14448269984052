export const Footer = ({ extraText }: { extraText?: string }) => {
  return (
    <div className="mx-auto pt-4 pb-5 text-muted text-center wp-footer">
      Built with ❤️ by{' '}
      <a href="https://twitter.com/dharmesh" target="_blank" rel="noreferrer">
        @dharmesh
      </a>
      {extraText && <> | {extraText}</>}
    </div>
  )
}
