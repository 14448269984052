import { useState, useEffect } from 'react'
import { API_SANDBOX_GRID } from '../../constants/api'
import { api } from '../../lib/api'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

export const SandboxGrid = () => {
  const [data, setData] = useState<any>([])
  const [columns, setColumns] = useState<any>([])

  useEffect(() => {
    ;(async () => {
      try {
        const response = await api.get(API_SANDBOX_GRID)
        setData(response?.data?.rows || [])
        let columnsData = []
        if (response?.data?.columns) {
            for (var i in response?.data?.columns) {
                columnsData.push({field: response?.data?.columns[i]})
            }
        }
        setColumns(columnsData || [])
      } catch {}
    })()
  }, [])

  return (
    <div className="mx-auto px-4 pt-5" style={{ maxWidth: 810 }}>
      <h1>Game Solution</h1>
      
      { data && (
          <div className="ag-theme-alpine" style={{height: 400, width: 803}}>
          <AgGridReact
              rowData={data}
              columnDefs={columns}
              editType='fullRow'>
          </AgGridReact>
      </div>
      )}
    </div>
  )
}

