const transferFeatures = [
  {
    id: 1,
    name: 'Practice as much as you want',
    description:
      "You get better with practice. And, it's hard to get better when you can only play once a day. WordPlay lets you practice/play as much as you want.",
  },
  {
    id: 2,
    name: "It's free, not 'free for now'",
    description: `WordPlay is a nights/weekends project for me. I do it for fun
      and to bring joy to others. It is free to play and will not be put behind a pay wall.`,
  },
  {
    id: 3,
    name: "It's educational -- sort of",
    description: `I built this for my son and my nieces. As such I plan
       to sneak in some actual education while playing.
       We already have definitions for words. I'll also add synonyms, rhymes and other things. Stay tuned.`,
  },
]

export const AboutPage = () => {
  return (
    <div className="py-5 overflow-hidden">
      <div className="position-relative mx-auto px-4" style={{ maxWidth: 800 }}>
        <svg
          className="position-absolute"
          style={{ left: '105%', opacity: 0.1 }}
          width={404}
          height={784}
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect x={0} y={0} width={4} height={4} fill="currentColor" />
            </pattern>
          </defs>
          <rect width={404} height={784} fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)" />
        </svg>

        <div className="text-center pb-4">
          <h1>WordPlay - The Totally Unofficial Wordle Practice Game</h1>
          <p className="text-body">Do you love word games? So do I. A lot.</p>
        </div>

        <div>
          <div>
            <h3>What is this?</h3>
            <p className="text-body">
              Simple. WordPlay is a word guessing game similar to the web game Wordle (and based on
              the concept in the TV game show Lingo). You may also know it as the game with green
              and yellow squares.
            </p>

            <p className="text-body">
              IMPORTANT NOTE: WordPlay is not associated with Wordle in any way. I'm just a fan of
              Wordle (like millions of others) and wanted a way to practice/play the game more.
              Wordle is now owned by The New York Times.
            </p>

            <p className="text-body">
              OK, fine. But how is this different from the official Wordle? Why would I play this
              instead of Wordle? You wouldn't. I still play Wordle every day. But in-between, I play
              WordPlay. And, if I want to challenge friends, I play WordPlay.
            </p>
            <p className="text-body">
              But, there are some differences. WordPlay is better in some ways, and not-so-better in
              others.
            </p>

            <div style={{ marginTop: 60 }}>
              {transferFeatures.map((item) => (
                <div key={item.id} className="d-flex pb-3">
                  <img style={{ height: 48, borderRadius: 8 }} src="/logo192.png" alt="" />
                  <div className="ps-4">
                    <h6 className="mb-2">{item.name}</h6>
                    <p className="text-body">{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
