import ChartistGraph from 'react-chartist'
import './styles.scss'

export const PieChart = ({
  labels,
  series,
  options = {},
  padding,
  formatLabel = (label) => label,
  formatValue = (value) => value,
}: {
  labels: string[]
  series: number[]
  options?: any
  padding?: string
  formatLabel?: (label: string) => string
  formatValue?: (value: number) => number | string
}) => (
  <div className="wz-pie-chart" style={{ padding }}>
    <ChartistGraph
      type="Pie"
      data={{ series }}
      options={{ width: '80px', height: '80px', showLabel: false, ...options }}
    />
    <div style={{ paddingLeft: 10 }}>
      {labels.map((label, index) => (
        <div key={label} className="d-flex fw-medium fs-sm">
          <div style={{ height: 18, width: 18, borderRadius: 4, background: getColor(index) }} />
          <div style={{ paddingRight: 4, paddingLeft: 8 }}>{formatLabel(label)}</div>
          <div>{formatValue(series[index])}</div>
        </div>
      ))}
    </div>
  </div>
)

const getColor = (index: number) => {
  if (index === 0) return '#22c55e'
  if (index === 1) return '#ef4444'
  if (index === 2) return '#0ea5e9'
  if (index === 3) return '#f97316'
  if (index === 4) return '#a855f7'
  if (index === 5) return '#eab308'
}
