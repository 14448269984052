import { merge } from 'lodash'

type KeyT =
  | 'games'
  | 'dailyGameWonOn'
  | 'gameStats'
  | 'gameUserId'
  | 'gameProfile'
  | 'userTypedSomething'
  | 'howToPlay'
  | 'darkMode'
  | 'highContrastMode'
  | 'keyboardLayout'
  | 'speedMode'
  | 'unlimitedWelcome'

export const createBrowserStorage = <T extends any>(
  key: KeyT,
  options: { type?: 'local' | 'session' } = {},
) => {
  const storage = options.type === 'session' ? sessionStorage : localStorage
  return {
    get: () => {
      return (safeParseJSON(storage.getItem(key)) || {}) as T
    },
    set: (data: T) => {
      storage.setItem(key, safeStringifyJSON(data))
    },
    merge: (data: Partial<T>) => {
      const currentData = safeParseJSON(storage.getItem(key)) || {}
      storage.setItem(key, safeStringifyJSON(merge(currentData, data)))
    },
    clear: () => {
      storage.removeItem(key)
    },
  }
}

const safeParseJSON = (str: any) => {
  try {
    return JSON.parse(str)
  } catch {
    return {}
  }
}

const safeStringifyJSON = (data: any) => {
  try {
    return JSON.stringify(data)
  } catch {
    return '{}'
  }
}
