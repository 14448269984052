import queryString from 'query-string'
import { isNil, omitBy, pick, omit } from 'lodash'
import { state } from '../state'

export const objectToQueryString = (obj: any, options = {}) =>
  queryString.stringify(omitBy(obj, isNil), {
    arrayFormat: 'bracket',
    ...options,
  })

export const queryStringToObject = (str: string, options = {}) =>
  queryString.parse(str, {
    arrayFormat: 'bracket',
    ...options,
  })

export const pickFromQueryString = (str: string, keys: string[]) =>
  objectToQueryString(pick(queryStringToObject(str), keys))

export const omitFromQueryString = (str: string, keys: string[]) =>
  objectToQueryString(omit(queryStringToObject(str), keys))

export const addToQueryString = (str: string, params: any) =>
  objectToQueryString({
    ...queryStringToObject(str),
    ...params,
  })

export const joinPathnameQuery = (pathname: string, query: string) =>
  `${pathname}${query ? '?' : ''}${query}`.replace('??', '?')

export const splitPathnameQuery = (url: string) => ({
  pathname: url.split('?')[0],
  query: url.split('?')[1] || '',
})

export const joinURL = (parts: any[]) => parts.filter((part) => !!part).join('/')

export const newGameURL = () => (state.gameType === 'core' ? '/new' : `/game/${state.gameType}`)
