import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { EmojiHappyIcon, PuzzleIcon, TrendingUpIcon } from '@heroicons/react/outline'
import { api } from '../../lib/api'
import { formatDate } from '../../lib/date-time'
import { times } from 'lodash'
import { Spinner } from '../Spinner'
import { Pagination } from '../Pagination'
import { PieChart } from '../charts/PieChart'

type GamesStatsT = {
  average_score: number
  games_played: number
  games_won: number
  longest_streak: number
  current_streak: number
  max_score: number
  solve_counts?: { [guessCount: number]: number }
  games: {
    challenge_id: null | string
    created_at: string
    game_id: number
    game_mode: null
    game_type: string
    id: number
    player_name: string
    guesses: string[]
    score: null | number
    solution: string
    solved: null | boolean
    updated_at: string
    user_token: string
  }[]
}

export const StatsPage = observer(() => {
  const [data, setData] = useState<null | GamesStatsT>(null)

  useEffect(() => {
    ;(async () => {
      try {
        const response = await api.get('/api/user/stats')
        setData({
          ...response.data,
          games: Object.values(response.data.games).map((game: any) => ({
            ...game,
            guesses: safeParseJSON(game.rounds_json) || [],
          })),
        })
      } catch {}
    })()
  }, [])

  if (!data) {
    return (
      <div className="d-flex pt-5 justify-content-center">
        <div className="spinner-border" />
      </div>
    )
  }

  const percentage_won = data.games_played ? Math.ceil(data.games_won / data.games_played * 100) : 0

  const current_streak = () => {
    let count = 0
    for (let i = data.games.length - 1; i >= 0; i--) {
      if (!data.games[i].solved) {
        return count
      }
      count+= 1
    }
  }

  const stats = [
    {
      name: 'Games Played',
      stat: data.games_played,
      icon: PuzzleIcon,
    },
    {
      customStat: (
        <div className="card-body">
          <div className="fw-medium">Win Ratio {percentage_won}%</div>
          <PieChart
            labels={['Wins', 'Losses']}
            series={[data.games_won, data.games_played - data.games_won]}
          />
        </div>
      ),
    },
    {
      name: 'Longest Win Streak',
      stat: data.longest_streak,
      icon: TrendingUpIcon,
    },
    {
      name: 'Current Win Streak',
      stat: data.current_streak ?? current_streak(),
      icon: TrendingUpIcon,
    },
    {
      name: 'Average Score',
      stat: Math.round(data.average_score),
      icon: TrendingUpIcon,
    },
    {
      name: 'Max Score',
      stat: data.max_score,
      icon: TrendingUpIcon,
    },
  ]

  const solveCounts = data.solve_counts || {}
  const guessCounts = Object.keys(solveCounts).map((i) => Number(i))
  const maxGuessCount = Math.max(...guessCounts)
  const winDistribution = times(maxGuessCount, (i) => solveCounts[i + 1] || 0)
  const maxValue = Math.max(...winDistribution)

  return (
    <div className="container-fluid pt-5" style={{ maxWidth: 1200 }}>
      <h1 className="pb-4">Game Stats</h1>

      <div className="row gx-3 gy-3">
        {stats.map((item, i) => (
          <div key={i} className="col-12 col-sm-6 col-md-4 col-lg-3">
            <div key={i} className="card h-100">
              {item.customStat || (
                <div className="card-body d-flex align-items-start">
                  <div className="badge bg-primary p-2 me-3">
                    <item.icon style={{ width: 24 }} />
                  </div>
                  <div className="mt-n1">
                    <div className="fw-medium">{item.name}</div>
                    <div className="fs-3 fw-bold">{item.stat}</div>
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>

      <div className="pt-5">
        <h4>Guess Distribution</h4>
        <div>
          {winDistribution.map((value, i) => (
            <Progress key={i} index={i} size={90 * (value / maxValue)} label={String(value)} />
          ))}
        </div>
      </div>

      <GamesTable games={data.games} />
    </div>
  )
})

const GamesTable = ({ games }: { games: GamesStatsT['games'] }) => {
  const PER_PAGE = 10
  const [offset, setOffset] = useState(0)
  const paginatedGames = games.slice(offset, offset + PER_PAGE)
  const showTable = false

  return !showTable ? (
    <></>
  ) : (
    <>
      <div className="">
        <div className="">
          <div className="">
            <div className="">
              <table className="">
                <thead className="">
                  <tr>
                    <th scope="col" className="">
                      Game ID
                    </th>
                    <th scope="col" className="">
                      Date
                    </th>
                    <th scope="col" className="">
                      Score
                    </th>
                    <th scope="col" className="">
                      Solution
                    </th>
                    <th scope="col" className="">
                      Solved
                    </th>
                    <th scope="col" className="">
                      Guesses
                    </th>
                  </tr>
                </thead>
                <tbody className="">
                  {paginatedGames.map((game, i) => (
                    <tr key={i} className={i % 2 === 0 ? undefined : ''}>
                      <td className="">{game.id}</td>
                      <td className="">{formatDate(new Date(game.created_at))}</td>
                      <td className="">{game.score}</td>
                      <td className="">{game.solution}</td>
                      <td className={''}>{game.solved ? 'Yes' : 'No'}</td>
                      <td className="">{game.guesses.join(', ')}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <Pagination offset={offset} limit={PER_PAGE} totalCount={games.length} onChange={setOffset} />
    </>
  )
}

const Progress = ({ index, size, label }: { index: number; size: number; label: string }) => {
  return (
    <div className="d-flex py-1">
      <div className="fw-medium fs-md" style={{ width: 20 }}>
        {index + 1}
      </div>
      <div style={{ width: `${5 + size}%` }} className="badge bg-primary text-end fs-sm">
        {label}
      </div>
    </div>
  )
}

const safeParseJSON = (str: any) => {
  try {
    return JSON.parse(str)
  } catch {
    return []
  }
}
