import { observer } from 'mobx-react-lite'
import { useState, useEffect } from 'react'
import { api } from '../lib/api'
import { setState, state } from '../state'
import { API_CREATE_USER, API_USER_LOGIN } from '../constants/api'
import { userIdStorage, userProfileStorage } from '../lib/localStorage'
import { Analytics } from '../lib/analytics'
import { useAuth0 } from '@auth0/auth0-react'

export const LoginButtons = observer(({ className }: { className: string }) => {
  const { user, isAuthenticated } = useAuth0()
  const { loginWithRedirect } = useAuth0()
  const { logout } = useAuth0()

  const user_token = userIdStorage.get().userId
  const [isSignedIn, setIsSignedIn] = useState(
    false || state.isLoggedIn || isAuthenticated || user?.email !== undefined,
  )

  useEffect(() => {
    setIsSignedIn(!!isAuthenticated)
  }, [isAuthenticated])

  useEffect(() => {
    if (isAuthenticated) {
      setState({ playerName: user?.name || user?.nickname })
      setIsSignedIn(user?.email !== undefined)
      setState({ isLoggedIn: false })

      const createUserViaAPI = async () => {
        await api.post(API_CREATE_USER, {
          user_token,
          givenName: user?.given_name,
          familyName: user?.family_name,
          email: user?.email,
          name: user?.name,
          imageUrl: user?.picture,
          googleId: user?.sub,
          accessToken: null,
        })

        let userLoginData = await api.post(API_USER_LOGIN, {
          user_token,
          method: user?.sub?.split('|')[0],
          user_id: user?.sub,
          email: user?.email,
          data: user,
        })
        if (userLoginData?.data?.new_user) {
          Analytics.tag('New User Signup')
        }

        return userLoginData;
      }
      const userLoginData = createUserViaAPI()
        .catch(console.error)
        .then((r) => {
          try {
            userIdStorage.set({ userId: r?.data?.user_token })
            setState({
              userToken: r?.data?.user_token,
            })
          } catch {}
        })

      if (user) {
        userProfileStorage.set({
          data: {
            email: user?.email,
            first_name: user?.given_name,
            last_name: user?.family_name,
            image_url: user?.picture,
          },
        })
        setState({ playerName: user?.given_name || user?.nickname })
        setIsSignedIn(true)
        setState({ isLoggedIn: true })
        Analytics.tag('Login - Google Login')
      }
    }
  }, [isAuthenticated, user, user_token, setIsSignedIn])

  const doLogin = () => {
    Analytics.tag('Login')
    loginWithRedirect()
  }

  const doLogout = () => {
    userProfileStorage.set({ data: null })
    setIsSignedIn(false)
    setState({ isLoggedIn: false })
    logout({ returnTo: window.location.origin })
    Analytics.tag('Login - Google Logout')
  }

  return (
    <>
      {!isSignedIn ? (
        <button className={className} type="button" onClick={() => doLogin()}>
          Log In
        </button>
      ) : (
        <button className={className} type="button" onClick={() => doLogout()}>
          Logout
        </button>
      )}
    </>
  )
})
