import pubsub from 'sweet-pubsub'

const tagId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID || '';
const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID || ''
}

declare const window: any;

const tagIdForGA = () => {
  return tagId.split('-').join('_')
}

const analyticsCall = (event: string, data: any) => {    
    if (window.ga) {
        if (event === 'pageview') {
            window.ga('gtag_'+tagId+'.send', 'pageview', data)
        } else if (event === 'event') {
            window.ga('gtag_'+tagId+'.send', 'event', data)
        } 
    }
}


const getGtmDataLayer = (event: string) => {
    const tagManagerDL = {
        dataLayer: {
          page: window.location.pathname + window.location.search,
          event: event,
          timestamp: new Date().getTime()
        },
        dataLayerName: 'PageDataLayer',
    };

    pubsub.emit('gtm_event', tagManagerDL)
    return tagManagerDL;
}

export const Analytics = {
    pageview: (data: any) => analyticsCall('pageview', data),
    
    event: (data: any) => analyticsCall('event', data),

    tag: (event: string) => getGtmDataLayer(event),
}