import { useState } from 'react'
import { API_FIRST_WORD_ANALYSIS } from '../../constants/api'
import { api } from '../../lib/api'
import { notification } from '../Notification'

type AnalysisT = {
  breakdown: {
    letter: string
    matches: string
    occurances: string
  }[]
  definition: string
  results: {
    percentile: number
    rank: number
    score: number
  }
  total_words: number
  usage: number
  word: string
  error_message?: string
}

export const FirstWordPage = () => {
  const [value, setValue] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [analysis, setAnalysis] = useState<null | AnalysisT>(null)

  const handleSubmit = async () => {
    if (!value.trim()) return
    setIsSubmitting(true)
    try {
      const response: { data: AnalysisT } = await api.get(API_FIRST_WORD_ANALYSIS, {
        params: { word: value.trim() },
      })
      if (response.data.error_message) {
        notification.danger({ title: response.data.error_message })
        setIsSubmitting(false)
        return
      }
      setAnalysis({ ...response.data, word: value })
    } catch (error: any) {
      notification.danger({ title: error.message })
    }
    setIsSubmitting(false)
  }

  return (
    <div className="mx-auto px-4 pt-5" style={{ maxWidth: 600 }}>
      <h1>FirstWord For Wordle</h1>
      <p className="text-body">How good is your first word for Wordle?</p>
      <p className="text-body">
        This app gives you a grade of how good your first word guess is for Wordle. Just enter a
        valid 5 letter word and get your grade.
      </p>

      <div className="input-group mb-5">
        <input
          className="form-control"
          autoFocus
          type="text"
          name="word"
          placeholder="Enter Word"
          onChange={(e) => setValue(e.target.value)}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              handleSubmit()
            }
          }}
          value={value}
        />
        <button
          className="btn btn-primary"
          type="button"
          onClick={handleSubmit}
          disabled={isSubmitting}
        >
          {isSubmitting && <span className="spinner-border spinner-border-sm me-2" />}
          Grade
        </button>
      </div>

      {analysis && (
        <>
          <h2>Summary</h2>
          <div className="mb-5">
            <p className="mb-4">
              <strong>{analysis.word.toUpperCase()}</strong> gets a percentile grade of{' '}
              <strong>{analysis.results.percentile}/100</strong>
            </p>
            <p className="mb-4">
              It is ranked <strong>#{analysis.results.rank}</strong> out of{' '}
              <strong>{analysis.total_words}</strong> possible words.
            </p>
            <p className="mb-4">
              This word has been submitted <strong>{analysis.usage}</strong> times to the FirstWord
              app.
            </p>
            <p className="mb-4">
              Definition: <strong>{analysis.definition}</strong>
            </p>
          </div>

          <h2>Letter Analysis</h2>

          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Letter</th>
                  <th scope="col" style={{ background: `var(--wordplay-orange)`, color: 'white' }}>
                    Occurs
                  </th>
                  <th scope="col" style={{ background: `var(--wordplay-green)`, color: 'white' }}>
                    Matches
                  </th>
                </tr>
              </thead>
              <tbody>
                {analysis.breakdown.map((item, i) => (
                  <tr key={i}>
                    <td>{item.letter.toUpperCase()}</td>
                    <td>{item.occurances}</td>
                    <td>{item.matches}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  )
}
