import { state } from '../state'
import { createBrowserStorage } from './browser-storage'
import { uniqueDayId } from './date-time'

export const gamesStorage = createBrowserStorage<{
  daily?: { guesses: string[]; solution: string }
  challenges?: { [challengeId: string]: string[] }
}>('games')

export const getStoredGuesses = ({
  challengeId,
  mode,
  solution,
}: {
  challengeId?: string
  mode: 'daily' | 'random'
  solution: string
}) => {
  const { daily, challenges } = gamesStorage.get()
  if (challengeId) return challenges?.[challengeId]
  if (mode === 'daily' && solution === daily?.solution) return daily.guesses
}

export const setStoredGuesses = (guesses: string[]) => {
  const { challengeId, solution, mode } = state

  if (challengeId) {
    gamesStorage.merge({ challenges: { [challengeId]: guesses } })
  } else if (mode === 'daily') {
    gamesStorage.merge({ daily: { solution, guesses } })
  }
}

export const dailyGameStorage = createBrowserStorage<{ date: string }>('dailyGameWonOn')

export const saveDailyGameCompletedDate = () => {
  dailyGameStorage.set({ date: uniqueDayId(new Date()) })
}

export const wonTodayDailyGame = () => dailyGameStorage.get().date === uniqueDayId(new Date())

export const userIdStorage = createBrowserStorage<{ userId?: string }>('gameUserId')

export const userProfileStorage = createBrowserStorage<{ data?: any }>('gameProfile')

export const userTypedSomethingStorage =
  createBrowserStorage<{ state?: boolean }>('userTypedSomething')

export const howToPlayStorage = createBrowserStorage<{ seen?: boolean }>('howToPlay')

export const unlimitedWelcomeStorage = createBrowserStorage<{ seen?: boolean }>('unlimitedWelcome')

export const darkModeStorage = createBrowserStorage<{ isDark?: boolean }>('darkMode')

export const highContrastModeStorage = createBrowserStorage<{ isHighContrast?: boolean }>('highContrastMode')

export const keyboardLayoutStorage = createBrowserStorage<{ keyboardLayout?: string }>('keyboardLayout')

export const speedModeStorage = createBrowserStorage<{ isSpeedMode?: boolean }>('speedMode')
