export const Alert = ({
  isOpen,
  message,
  variant = 'warning',
}: {
  isOpen: boolean
  message: string
  variant?: 'success' | 'warning'
}) => {
  if (!isOpen) return null

  return (
    <div className="px-3" style={{ height: 0 }}>
      <div
        className="position-relative mx-auto"
        style={{
          top: -50,
          maxWidth: 500,
          borderRadius: 8,
          color: variant === 'success' ? 'var(--bs-success)' : 'var(--bs-danger)',
          background:
            variant === 'success'
              ? 'rgba(var(--bs-success-rgb), 0.25)'
              : 'rgba(var(--bs-danger-rgb), 0.25)',
        }}
      >
        <div className="py-2 px-4">
          <p className="fs-md text-center fw-semibold m-0">{message}</p>
        </div>
      </div>
    </div>
  )
}
