import { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { API_GET_RANDOM_USERNAME, API_SET_PLAYER_NAME } from '../constants/api'
import { notification } from '../components/Notification'
import { LinkSaveParams } from '../components/LinkSaveParams'
import { api, ChallengeResultsT, fetchChallengeResults } from '../lib/api'
import { setState, state } from '../state'
import { copyToClipboard } from '../lib/clipboard'
import { BaseModal } from './modals/BaseModal'
import { SetPlayerNameForm } from './SetPlayerNameForm'
import { isWinningWord } from '../lib/words'

export const ChallengeBox = observer(() => {
  const { challengeId, playerName, isGameLost, isGameWon } = state
  const [value, setValue] = useState('')
  const [results, setResults] = useState<ChallengeResultsT | null>(null)
  const [isCopied, setIsCopied] = useState(false)
  const [isPlayerNameFormModalOpen, setIsPlayerNameFormModalOpen] = useState(false)

  useEffect(() => {
    if (playerName) return
    ;(async () => {
      try {
        const response = await api.get(API_GET_RANDOM_USERNAME)
        setValue(response?.data?.username || '')
      } catch {}
    })()
  }, [playerName])

  useEffect(() => {
    const fetchResults = async () => {
      try {
        const response = await fetchChallengeResults(challengeId!)
        setResults(response)
      } catch (error: any) {
        notification.danger({ title: error.message })
      }
    }
    fetchResults()
    const interval = setInterval(fetchResults, 1000 * 15)
    return () => clearInterval(interval)
  }, [challengeId])

  const handleSubmitUsername = async () => {
    try {
      await api.get(API_SET_PLAYER_NAME, { params: { player_name: value } })
      setState({ playerName: value })
    } catch (error: any) {
      notification.danger({ title: error.message })
    }
  }

  return (
    <div className="mx-auto mb-4" style={{ maxWidth: 360 }}>
      <div className="card">
        <div className="card-body">
          <dl className="d-flex align-items-center mb-2">
            <dt className="fs-md m-0">Challenge:</dt>
            <dd className="m-0 ms-2">{results?.challenge_name.replace(/ challenge/i, '')}</dd>
          </dl>
          {playerName && (
            <dl className="d-flex align-items-center mb-2">
              <dt className="fs-md m-0">Username:</dt>
              <dd className="m-0 ms-2">
                {playerName}
                <span
                  className="link-primary ms-2"
                  style={{ cursor: 'pointer' }}
                  onClick={() => setIsPlayerNameFormModalOpen(true)}
                >
                  Edit
                </span>
              </dd>
            </dl>
          )}
          {!!results?.players.length && <div className="mt-3" />}
          {results?.players.map((player, i) => (
            <dl className="d-flex align-items-center mb-2" key={i}>
              <dt className="fs-md m-0">
                {player.name}
                {player.name.toLowerCase() === state.playerName?.toLowerCase() && ' (you)'}:
              </dt>
              <dd className="m-0 ms-2">{getGuessLabel(player)}</dd>
            </dl>
          ))}
          {!playerName && (
            <>
              <p className="fs-sm fw-medium mt-3">
                Enter your player name, or use the fun one we made up for you. You can change it
                later.
              </p>
              <div className="input-group">
                <input
                  className="form-control form-control-sm"
                  autoComplete="off"
                  type="text"
                  name="word"
                  placeholder="Enter name"
                  onChange={(e) => setValue(e.target.value)}
                  onKeyDown={(e) => {
                    e.stopPropagation()
                  }}
                  onKeyUp={(e) => {
                    e.stopPropagation()
                    if (value.trim() && e.code === 'Enter') {
                      handleSubmitUsername()
                    }
                  }}
                  value={value}
                />
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  onClick={handleSubmitUsername}
                  disabled={!value.trim()}
                >
                  Set
                </button>
              </div>
            </>
          )}
          <div className="d-flex flex-wrap align-items-center mt-4">
            {(isGameWon || isGameLost) && (
              <LinkSaveParams to="/challenge/results">
                <button type="button" className="btn btn-primary btn-sm me-2">
                  View Results
                </button>
              </LinkSaveParams>
            )}
            <button
              type="button"
              className="btn btn-primary btn-sm"
              style={{ width: 'auto' }}
              onClick={() => {
                copyToClipboard(window.location.href)
                setIsCopied(true)
                setTimeout(() => setIsCopied(false), 2000)
              }}
            >
              {isCopied ? 'Link Copied To Clipboard' : 'Copy Challenge Link'}
            </button>
          </div>
        </div>
      </div>

      <BaseModal
        maxWidth={500}
        isOpen={isPlayerNameFormModalOpen}
        isTextCenter={false}
        title="Set Player Name"
        handleClose={() => setIsPlayerNameFormModalOpen(false)}
      >
        <div className="modal-body">
          <SetPlayerNameForm onSuccessSubmit={() => setIsPlayerNameFormModalOpen(false)} />
        </div>
      </BaseModal>
    </div>
  )
})

const getGuessLabel = (player: { name: string; guesses: Array<string> }) => {
  const isWin = player.guesses.some((guess) => isWinningWord(state.solution, guess))
  const isLoss = !isWin && player.guesses.length === state.guessCount
  if (isWin) return `Won on guess ${player.guesses.length}`
  if (isLoss) return `Didn't solve`
  return `Working on guess ${player.guesses.length + 1}`
}
