import { InformationCircleIcon } from '@heroicons/react/outline'
import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'

import { API_GET_TURN_MESSAGE, API_USER_SYNC_LOCAL_STORAGE } from '../../constants/api'
import { BaseModal } from '../modals/BaseModal'
import { setState, state } from '../../state'
import { api } from '../../lib/api'
import { userIdStorage } from '../../lib/localStorage'
import { getFlipDurationTotalMs } from './Cell/FlipCell'
import { userTypedSomethingStorage } from '../../lib/localStorage'
import './message.scss'

export const Message = observer(({ index }: { index: number }) => {
  const { solution, challengeId, playerName, guesses } = state
  const user_token = userIdStorage.get().userId
  const guess = guesses.length > index ? guesses[index] : null

  const [msg, setMsg] = useState<null | string>(null)
  const [isOpen, setIsOpen] = useState(false)
  const [userTypedSomething, setUserTypedSomething] = useState(
    !!userTypedSomethingStorage.get().state,
  )

  useEffect(() => {
    if (userTypedSomething || index !== 0) return

    setMsg('Just start typing...')

    const listener = () => {
      setMsg(null)
      setUserTypedSomething(true)
      userTypedSomethingStorage.set({ state: true })
    }
    window.addEventListener('keyup', listener)
    return () => {
      window.removeEventListener('keyup', listener)
    }
  }, [userTypedSomething, index])

  useEffect(() => {
    ;(async () => {
      if (!guess || guesses.length !== index + 1) return

      try {
        const response = await api.post(API_GET_TURN_MESSAGE, {
          turn: index + 1,
          words: guesses,
          solution,
          challenge_id: challengeId,
          player_name: playerName,
          user_token,
        })

        // api.post(API_USER_SYNC_LOCAL_STORAGE, { local_storage: localStorage })

        setTimeout(() => {
          setMsg(response?.data?.message)
          setState({ score: response?.data?.score })
        }, getFlipDurationTotalMs())
      } catch {}
    })()
  }, [challengeId, playerName, guess, guesses, index, solution, user_token])

  if (!msg) return <div className="wp-cell-message wp-cell-message-empty d-flex align-items-center ms-3 mb-1" />

  return (
    <>
      <div
        className="wp-cell-message wp-cell-message-desktop align-items-center ms-3 mb-1 rounded fs-sm px-3 text-body"
      >
        <div className="wordplay-message" dangerouslySetInnerHTML={{ __html: msg }} />
      </div>
      <div
        className="wp-cell-message wp-cell-message-mobile align-items-center ms-2 mb-1"
        onClick={() => setIsOpen(true)}
      >
        <InformationCircleIcon style={{ width: 22, minWidth: 22 }} />
      </div>
      <br />
      <BaseModal title="Tip" isOpen={isOpen} handleClose={() => setIsOpen(false)}>
        <div className="modal-body">
          <div className="wordplay-message mb-4" dangerouslySetInnerHTML={{ __html: msg }} />
          <button className="btn btn-primary btn-sm" onClick={() => setIsOpen(false)}>
            Okay
          </button>
        </div>
      </BaseModal>
    </>
  )
})
