import { Fireworks } from 'fireworks-js/dist/react'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { state } from '../state'

export const WinFireworks = observer(() => {
  const { isGameWon } = state
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (!isGameWon) return
    setShow(true)
    setTimeout(() => setShow(false), 7000)
  }, [isGameWon])

  if (!show) return null

  return (
    <Fireworks
      options={{
        speed: 3,
      }}
      style={{
        pointerEvents: 'none',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        position: 'fixed',
        background: 'none',
      }}
    />
  )
})
