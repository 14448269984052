// should replace this with a date lib once more dates are used
export const formatDate = (date: Date) => {
  let val = date.toDateString().replace('2022', '').split(' ')
  val.splice(1, 0, ',')
  return val.join(' ').replace(' ,', ',')
}

export const uniqueDayId = (date: Date) => {
  return [padTo2Digits(date.getMonth() + 1), padTo2Digits(date.getDate()), date.getFullYear()].join(
    '/',
  )
}

const padTo2Digits = (num: number) => {
  return num.toString().padStart(2, '0')
}
