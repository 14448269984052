import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { API_SET_PLAYER_NAME } from '../constants/api'
import { api } from '../lib/api'
import { setState, state } from '../state'

export const SetPlayerNameForm = observer(
  ({ onSuccessSubmit }: { onSuccessSubmit?: () => void }) => {
    const [name, setName] = useState(state.playerName || '')

    const [isSubmitting, setIsSubmitting] = useState(false)

    const handleSubmit = async () => {
      setIsSubmitting(true)
      try {
        await api.get(API_SET_PLAYER_NAME, { params: { player_name: name } })
        setState({ playerName: name })
        setName(name)
        onSuccessSubmit?.()
      } catch {}
      setIsSubmitting(false)
    }

    return (
      <div>
        {/* <label htmlFor="player_name" className="form-label">
          Player Name
        </label> */}

        <div className="input-group">
          <input
            className="form-control"
            autoComplete="off"
            type="text"
            name="player_name"
            placeholder="Set Player Name"
            onChange={(e) => setName(e.target.value)}
            onKeyDown={(e) => {
              if (name.trim() && e.code === 'Enter') {
                handleSubmit()
              }
            }}
            value={name}
          />
          <button
            className="btn btn-primary"
            type="button"
            onClick={() => handleSubmit()}
            disabled={isSubmitting || !name.trim()}
          >
            {isSubmitting && <span className="spinner-border spinner-border-sm me-2" />}
            Set
          </button>
        </div>
      </div>
    )
  },
)
